import React from 'react';
/**
 * ClientQuizList renders a list of quizzes which are the client's own custom quizzes.
 * @param {object} data - contains the list of custom quizzes {crs_q, cnf_q}
 * @param {string} activeTab - tab name which is currently active
 * @param {Function} setAssetData - sets the asset data  {assetData}
 * @param {object} assetData - contains all the assets state handling filters
 * @param {Function} setSelectedContent - sets the selected content 
 * @param {Function} assetsSearch - searches the assets (search box handler)
 * @param {Function} setIsDrawer - sets if the drawer is open or not
 * @param {React.Component} TabPanel - tab panel component 
 */
 const ClientQuizList = ({data,activeTab,setAssetData,assetData,setSelectedContent,assetsSearch,setIsDrawer, TabPanel}) => {
  return (<>
   <TabPanel value={activeTab} index={1}>
      <div className='row outer-row client-quiz'>
        {assetsSearch(data,setAssetData,'cnf_q')}
        <div className='col-lg-24'>
        <> {Object.keys(assetData?.cnf_q)?.length > 0 && Object.values(assetData?.cnf_q)?.map((item, index) => <div key={index} className='listing-card-quizzes' onClick={() => { setSelectedContent(item); setIsDrawer(true) }}>
            <span className='client-quiz-list'>{`${index + 1}.  ${item?.question}`}</span></div>)}
         </>
        </div>
      </div>
    </TabPanel></>
  )
}

export default ClientQuizList;