import React, { useState, useRef , useEffect} from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Celogo from '../../../assets/img/ce_author.png'
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Search from '../Search/Search';
import EditMenuDropdown from '../../../editor/components/EditMenuDropDown/EditMenuDropDown';
import * as courseInfoAction from '../../../redux/actions/courseInfoAction'
import * as courseEditAction from '../../../redux/actions/courseEditAction'
import * as courseTrackingAction from '../../../redux/actions/courseTrackingAction'
import { useSelector, useDispatch } from 'react-redux';
import CustomPopover from '../CustomPopover/CustomPopover';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Tooltip } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import DataHelper from '../../../utils/DataHelper';
import ACLHelper from '../../../utils/ACLHelper';
import { useHistory } from 'react-router-dom';
import CePlayer from '../../../assets/img/ce_logo_skill_comp.png'
import './navbar.scss';
import './linearHeader.scss';
import profile_icon from '../../../assets/img/profile_icon.png';
import SettingsIcon from '@mui/icons-material/Settings';
import useStorage from '../../../utils/hooks/useStorage';
import CustomSweetAlert from "../CustomSweetAlert/CustomSweetAlert";
import MuiIcons from '../MuiIcons/MuiIcons';
import t from '../../../translation/useTranslate';
import BuildHelper from '../../../utils/BuildHelper';

const NavBar = ({templateList, resolutionList, handleAttestation, componentList, help, profile, navigateHome, handleStyle, courseCompleted}) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const deviceRef = useRef('')
    const templateRef = useRef('')
    const compRef = useRef('')
    const printRef = useRef('')
    const profileRef = useRef('')
    const [ref, setRef]= useState('')
    const [icon, setIcon] = useState(<MuiIcons iconName='desktop'/>)
    const [open, setOpen] = useState(false);
    const isLearner=BuildHelper?.isLearner();

    const [isPublish, setIsPublish] = useState(false);
    // const [isCnPublish, setIsCnPublish] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const courseEdit = useSelector(state => state.courseEdit)
    const courseInfo = useSelector(state => state.courseInfo);
    const userInfo = useSelector(state => state.userInfo);
    const {globalStyle,courseTracking} = useSelector(state => state);
    const arrowRef = useRef(null);

    const  updates = courseInfo?.supplementaries?.updates
    const [styleObj, setStyle] = useState({})
    const [menuList, setMenu] = useState([]);
    const [hasCompleted, setHasCompleted] = useState(false);
    const [editMenuOpened, setEditMenu] = useState('');
    const [closeAlert,setCloseAlert] = useState(false)
    const [isSmallMenu,setIsSmallMenu] = useState(false);
    const configId = window?.ce?.rlm?.config_id || "1";
    const closeIcon=BuildHelper.isOnScorm() ? 'close' : 'power_settings';

    const [templateMenu,setTemplateMenu] = useState(templateList);

    const storage = useStorage({ 
      keys: [configId], 
      defaultValue:{
      mail:"",
      attestation:"-1"
    },
    storageType: 'session',});
    let crsStorage =storage &&  storage[`${configId}`]?.get();
    const  VIEW_STATE = {
      HOME :'NAV_GO_HOME', RESPONSIVE:'NAV_RESPONSIVE_VIEW'
    }
    const [logoPath, setLogoPath] = useState(window.ce.isStandalone ? window.ce.siPath : (!isLearner ? Celogo : CePlayer));
    useEffect(() => {
      try{
        if(isLearner){
          const clientId = userInfo?.user?.pvtid
          const fileExtension = userInfo?.user?.logo ? userInfo?.user?.logo.split(".").pop() : 'png';
          if(clientId && fileExtension && Number(clientId)!==139){
                  setLogoPath('https://dg7zdxz92ywv.cloudfront.net/platform/clientlogo/'+clientId+'.'+fileExtension)
          }
        }
          }catch{}
    }, [userInfo])

    /** Only for the course learning element search should come 
     *  if its a course then pusing the item after saveastemplate
     */
    useEffect(() => {
      const featureEnabled3_1 = ACLHelper.isFeatureEnabled(3, 1, -1);
      const featureEnabled3_2 = ACLHelper.isFeatureEnabled(3, 2);
      const isCourse = DataHelper?.isCourse();
    
      // create a copy of the templateList to work with
      let updatedTemplateList = [...(templateList || [])];
    
      const existingNames = new Set(updatedTemplateList.map(item => item?.name));
    
      // helper function to add items to the list if they don't exist
      const addItemIfNotExists = (item) => {
        if (!existingNames.has(item.name)) {
          updatedTemplateList.push(item);
          existingNames.add(item.name); // Track newly added items
        }
      };
    
      if (!isLearner) {
        addItemIfNotExists({
          name: "Comments",
          editDrawer: {
            width: 80,
            name: 'Learning element Comments',
            panel: 11
          }
        });
    
        if (BuildHelper?.isConfig()) {
          addItemIfNotExists({
            name: "List Of Customization",
            editDrawer: {
              width: 75,
              name: 'List Of Customization',
              panel: 13
            }
          });
          
          if(BuildHelper.hasLocked()){
            addItemIfNotExists({
              name: "Course Structure",
              editDrawer: {
                width: 75,
                name: 'Course Structure Update',
                panel: 15
              }
             });
            }
          }
    
        if (featureEnabled3_2 && !DataHelper.isPublish()) {
          addItemIfNotExists({
            name: 'Content Templates',
            editDrawer: {
              width: 80,
              name: 'Content template Panel',
              panel: 3
            }
          });
        }
    
        if (featureEnabled3_1 && !DataHelper?.isPublish()) {
          addItemIfNotExists({
            name: "Create Template",
            editDrawer: {
              width: 25,
              name: 'Create Template Panel',
              panel: 7
            }
          });
        }
    
        if (isCourse) {
          addItemIfNotExists({
            name: "Search Learning elements",
            editDrawer: {
              width: 80,
              name: 'Search Learning elements',
              panel: 9
            }
          });
        }
    
        if (courseEdit?.publishVersion?.length > 0) {
          addItemIfNotExists({
            name: "Publish Version",
            editDrawer: {
              width: 80,
              name: 'Publish Version History',
              panel: 12
            }
          });
        }
    
        addItemIfNotExists({
          name: "Global Settings",
          editDrawer: {
            width: 36,
            name: 'GLOBAL SETTINGS',
            panel: 10
          }
        }, updatedTemplateList);
      }
    
      //correct order
      const desiredOrder = [
        "Comments",
        "Status",
        "List Of Customization",
        "Content Templates",
        "Create Template",
        "Search Learning elements",
        "Publish Version",
        "Course Structure",
        "Global Settings",
      ];
    
      // sort the templateList based on the desired order
      updatedTemplateList = updatedTemplateList.sort((a, b) => {
        return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name);
      });
    
      // update the state with the sorted templateList
      setTemplateMenu(updatedTemplateList);
    
    }, [ACLHelper.isFeatureEnabled(3, 1, -1), ACLHelper.isFeatureEnabled(3, 2), courseEdit?.publishVersion, templateList]);
    
    
    
    
    

    

    const handleMenuToggle = (ref, data, name) => {
        dispatch(courseEditAction.isMenuDropDown(true))
        setRef(ref)
        setMenu(data)
        setEditMenu(name);
        //menu arrow icon position
        if (ref.current && arrowRef.current) {
          // Get the dimensions of the iconRef and the arrow
          const iconRef = ref.current.getBoundingClientRect();
          const popperLeft = iconRef.left;

          // Calculate the exact position for the arrow
          const arrowLeft = popperLeft+18;
          const arrowTop = iconRef.height+15; // Adjust this based on the arrow
          arrowRef.current.style.position = 'absolute';
          arrowRef.current.style.left = `${arrowLeft}px`;
          arrowRef.current.style.top = `${arrowTop}px`;
          arrowRef.current.style.transform = 'translateX(-50%)';
        }
    }
    //  publish action
    const handleIsPublish=()=>{
    try{
      setIsPublish(false);
      // formatting publish json payload
      const publishJson={
        publish:{
          courseid:window?.ce?.rlm?.config_id||"",
          version_json:{...(courseEdit?.publish||{})},
          author:userInfo?.user?.uid||"",
        },
        flatten:courseInfo?.flatten,
      }
      publishJson.versionList=[...courseEdit?.publishVersion,publishJson?.publish];
      dispatch(courseEditAction.publishChangesToCourse(publishJson));
      goHome();
      if (window && window?.ce && window?.ce?.platform_scorm) {
          window.ce.platform_scorm.ispublished = false;
       }
    }catch(e){}
    }
    

    const handlePopover=()=>{
      setAnchorEl(null)
      if(!window?.ce?.platform_scorm?.ispublished) return;
      setIsPublish(true);
    }

    const handleClose = () =>{
      setIsPublish(false);
    }

    // const handlePublish = () => {
    // try{
    //   const lt = courseEdit?.publish || {};
    //   if (!lt) return; //publish or crsStorage is null or undefined
    //   const publish = { lt, ar: {} };
    //   // archived data creation
    //   for (const [key1, topic] of Object.entries(publish.lt ?? {})) {
    //     for (const [key2, lrn] of Object.entries(topic?.lrncontent ?? {})) {
    //       const crtTopic = trackInfo.flatten[lrn?.nodeId];
    //       // find the corresponding LRN in the topic's 'lrncontent'
    //       const arLRN = crtTopic?.lrncontent?.find(e => e?._id === key2);
    //       const arTopic = publish.ar[key1] || {};
    //       publish.ar[key1] = { markup:crtTopic?.markup??"",lrncontent:{...(arTopic?.lrncontent||{}),[key2]:{...(arLRN?.props ?? null)}}  };
    //     }
    //   }
    //   console.log("publish", publish);
    // }catch(e){
    //   console.error("******handlePublish at NavBar.js **********")
    // }
    
    // };
    
    
      const deviceResolution = (style) => {
        setStyle(style)
        handleStyle(style)
        switch(style.name) {
            case 'Laptop':
                setIcon(<MuiIcons iconName="laptop"  />)
                break 
            case 'Mobile':
                setIcon(<MuiIcons iconName="phone"  />)
                break 
            case 'Tablet':
                setIcon(<MuiIcons iconName="tablet"  />)
                break 
              case 'Desktop':
                setIcon( <MuiIcons iconName="desktop"  />)
                break;
            default: 
                setIcon(<MuiIcons iconName="desktop"  />)
                break 
          }
        navigate(VIEW_STATE.RESPONSIVE,style)
      }
    
/**
 * Toggles the sidebar state by incrementing the slider view value.
 */
  const handleClick = () => {
    let slider=(courseInfo.sideBar ?? 2);
    slider+=1;
    if(slider > 2) slider=1;
    dispatch(courseInfoAction.setSideBar(slider))
  }

    const handleDrawer = (topic, width='40%') => {
        const mediaEle = document.querySelectorAll('video, audio')
        if (mediaEle.length !== 0)
          for (const media of mediaEle) {
                media.pause()
          }
        dispatch(courseInfoAction.setDrawer({value: true, topic, width}))
      }

    const navigate = (type,...args) => {
        navigateHome({type:type, data:{view:args[0]}})
    }

    const closeCourse = ()=>{
      setCloseAlert(true);
    }

    const userCfClose = ()=>{
      setCloseAlert(false);
      let topWindow = (window.top === window.self) ? false : true;
				if(topWindow){
					window.top.close();
				}else{
					window.close();
				}

      // set lms finish here
    }

  const toggleMenuList = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const closeMenu = (event, topic, width) => {
    if (printRef.current && printRef.current.contains(event.target)) {
      return;
    }
    if (topic !== undefined) {
      handleDrawer(topic, width)
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);

  // Effect to handle when `courseInfo` changes and to update `hasCompleted`
  useEffect(() => {
    // Check if courseInfo and status are defined before updating the state
    const completed = Number(courseInfo?.flatten?.[1]?.status) === 1;
    
    // Only update hasCompleted if the status has truly changed
    if (completed !== hasCompleted) {
      setHasCompleted(completed);
    }
  }, [courseInfo?.flatten?.[1]?.status]);
  
  
  
  // Separate effect to handle `open` state and focusing printRef
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      printRef.current.focus();  // Focus when open closes
    }
    prevOpen.current = open;  // Track previous `open` state
  }, [open]);

    const userCancel =()=>{
      setCloseAlert(false);
    }

    const goHome =()=>{
      history.push('/');
    }
    const goLanguage =()=>{
      history.push('/');
      dispatch(courseTrackingAction.hasLanguage());
    }

  //  const isCertificate=()=>{
  //   if(Number(courseInfo?.flatten[1]?.status) !== 1 || crsStorage?.attestation !=="3") return true;
  //    return false
  //  }
  const timerRef = useRef(null);
  const handleMouseEnter = (e) => {
    let element=e.currentTarget
    timerRef.current = setTimeout(() => {
      if (element) {
        setAnchorEl(element);
      }
    },600); 
  };

  const handleMouseLeave = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const supplementaries=()=>{
    let hasNotes=(globalStyle?.headers?.n && courseInfo?.supplementaries?.notes?.length > 0);
    let hasVignettes=(globalStyle?.headers?.v && courseInfo?.supplementaries?.vignettes?.length > 0 );
    let hasUpdates=(globalStyle?.headers?.u && courseInfo?.supplementaries?.updates?.length > 0 )
    return <>
   {(hasNotes||hasVignettes||hasUpdates)? <div className='supplementary_updates'>
      {hasNotes && <Button color="inherit" onClick={() => handleDrawer('CE Notes')}>{t("CE_NOTE")}</Button>}
      {hasVignettes && <Button color="inherit"  onClick={() => handleDrawer('vignettes')}>{t("VIGNETTES")}</Button>}
      {hasUpdates && <Badge className='badge-ce-updates' badgeContent={updates?.length} color="primary">
      <Button color="inherit" onClick={() => handleDrawer('updates')}>{t("UPDATE")}</Button>
      </Badge>}
      </div>:<></>}
    </>
  }

   const getCertificate=(e)=>{
    crsStorage=storage[`${configId}`]?.get();
    const completed=Number(courseInfo?.flatten['1']?.status) === 1
    if(!hasCompleted||(crsStorage?.attestation !=="3" && BuildHelper.isConfig() && globalStyle?.attest?.is) ){
     if(hasCompleted)handleAttestation("1");
     return;
   }
    closeMenu(e, 'Certificate', '80%');
   }
    const popId =  Boolean(anchorEl) ? 'simple-popover' : undefined;
    // style={{'--global-font-family': `${globalStyle?.font || 'RobotoLight'}`}}
    return (
    <>
        <Box sx={{ flexGrow: 1 }} >
          {/*  use "tree-header" class for the ce header  class ${globalStyle?.isLinear ? 'linear-header' : 'tree-header'} */}
            <AppBar position="static" className={`linear-header `} >
                <Toolbar variant="dense" className="header-container">
                    {/* <IconButton className='left-menu' edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={() => { handleClick() }} > */}
                       <MuiIcons iconName="menu"  className='left-menu' edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={() => { handleClick() }} />
                    {/* </IconButton> */}
                    <Typography variant="h6" color="inherit" component="div" className={`img-container ${Number(window?.ce?.ceuser?.role_id) !== 4 && "edit-icon"}`} sx={{ flexGrow: 1 }}  >
                    <img src={logoPath} alt='' onClick={() => navigate(VIEW_STATE.HOME)} style={{ cursor: 'pointer' }}/>
                    </Typography>
                    {(isLearner && globalStyle?.headers?.s) && <Search />}
                    {isLearner && 
                     <div className='small-device-menu'>
                        {/* <IconButton  className='right-menu' edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={()=>setIsSmallMenu(!isSmallMenu)}> */}
                            {isSmallMenu ? <MuiIcons iconName="close"  className='right-menu' onClick={()=>setIsSmallMenu(!isSmallMenu)}  />  : <MuiIcons iconName="menu"  className='right-menu' onClick={()=>setIsSmallMenu(!isSmallMenu)}  />}
                        {/* </IconButton> */}
                        {isSmallMenu && <div className='small-device-menu'>
                            {isLearner &&<>
                              {globalStyle?.headers?.m && <Button className="header-course-map"  color="inherit" onClick={(e) => closeMenu(e, 'CourseMap', '100%')}>{t("P_CRS_MAP")}</Button>}
                              {globalStyle?.headers?.c && <Button className="header-certificate" color="inherit" onClick={(e) => getCertificate(e)} disabled={!hasCompleted}>{t("P_CERTIFICATE")}</Button> }
                               {(globalStyle?.headers?.n && courseInfo?.supplementaries?.notes?.length > 0) && <Button color="inherit" onClick={() => handleDrawer('CE Notes')}>{t("CE_NOTE")}</Button>}
                               {(globalStyle?.headers?.v && courseInfo?.supplementaries?.vignettes?.length > 0 )&& <Button color="inherit" onClick={() => handleDrawer('vignettes')}>{t("VIGNETTES")}</Button>}
                               {(globalStyle?.headers?.u && courseInfo?.supplementaries?.updates?.length > 0 )&& <Button color="inherit" onClick={() => handleDrawer('updates')}>{t("UPDATE")}</Button>}
                              </> }
                          </div>}
                          <Tooltip title="Close course" aria-label="close" placement='top'>
                           <span><MuiIcons iconName={closeIcon} className='power-icon' onClick={() =>closeCourse()}  /></span>
                          </Tooltip>
                      </div>
                      }
                   
                    <div className={`edit-tool-menu`}>
                      {(courseEdit?.publish && Object.keys(courseEdit?.publish)?.length > 0) && <>

                        {/* <span className="header-home info-icon" color="inherit" aria-describedby={idx}  onMouseEnter={(e)=>setAnchorEl(e.currentTarget)} onClick={(e)=>setAnchorEl(e.currentTarget)} >
                        <InfoIcon />
                      </span> */}
                        <Button className='btn publish-btn' aria-describedby={popId}  onMouseLeave={(e)=>handleMouseLeave(e)} onMouseEnter={(e)=>handleMouseEnter(e)} onClick={(e)=>setAnchorEl(e.currentTarget)} > {t("PUBLISH")}  
                          {/* <CloudUploadIcon />  */}
                          <MuiIcons iconName='cloud_upload' />
                           </Button>
                        {/* <Popover
                          id={popId}
                          className='publish-popover'
                          open={Boolean(anchorEl)}
                          anchorEl={anchorEl}
                          onClose={(e)=>setAnchorEl(null)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                    >
                      <div className='sweet-alert popover'>
                        <p>{t("PUBLISH_POP")}</p>
                        <div className='popover-btn'>
                        <Button className='btn-link' onClick={(e)=>setAnchorEl(null)}>{t("CANCEL")}</Button> <Button className='btn btn-lg btn-primary' onClick={handlePopover}>{t("OK")}</Button>
                          </div>  
                      </div>
                        
                    </Popover> */}
                      </>
                      }
                      {courseTracking?.crsProgress?.player_config_json?.i18n?.languages?.length > 1 && !hasCompleted && (<span className="language-icon" color="inherit" aria-describedby={popId} onMouseLeave={(e)=>handleMouseLeave(e)} onMouseEnter={(e)=>handleMouseEnter(e)} onClick={(e)=>setAnchorEl(e.currentTarget)}>
                         <MuiIcons iconName='translate' />
                        {/* <GTranslateIcon/> */}
                      </span>)}
                      <span className="header-home" color="inherit" onClick={()=>goHome()}>
                        <MuiIcons iconName="home" className="w-100 h-100"/>
                        {/* <HomeIcon/> */}
                      </span>
                      {isLearner &&<>
                        {globalStyle?.headers?.m && <Button className="header-course-map fw-500"  color="inherit" onClick={(e) => closeMenu(e, 'CourseMap', '80%')}>{t("P_CRS_MAP")}</Button>}
                        {globalStyle?.headers?.c && <Button className="header-certificate fw-500" color="inherit" onClick={(e) => getCertificate(e)} disabled={!hasCompleted}>{t("P_CERTIFICATE")}</Button>}
                      </>}
                    </div>

                    {isLearner ? <div className='top-bar-menu'>
                     { supplementaries()}
                    {/* <Button color="inherit" className={`help-icon`} title="Print" ref={printRef} onClick={toggleMenuList}>Print</Button> */}
                    <ArrowDropUpIcon className={`arrow-drop-up ${open && 'show-print-arrow'}`} />
                    {/* <MuiIcons iconName='drop_up_arrow'  className={`arrow-drop-up ${open && 'show-print-arrow'}`} /> */}
                    <Popper open={open} anchorEl={printRef.current} transition disablePortal>
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                          <Paper className='menu-list-popper'>
                            <ClickAwayListener onClickAway={closeMenu}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                <MenuItem onClick={(e) => closeMenu(e, 'CourseMap', '80%')}>{t("COURSE_MAP")}</MenuItem>
                                <MenuItem onClick={(e) => getCertificate(e)} disabled={courseInfo?.flatten['1']?.status!==1}>{t("CERTIFICATE")}</MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                    {/* <Tooltip title="Close course" aria-label="close" placement='top'>
                        <Button color="inherit" onClick={() =>closeCourse()} className='close-course-btn'><PowerSettingsNewIcon className='power-icon' /></Button>
                    </Tooltip> */}
                    <Tooltip title={t("CLOSE_CRS")} aria-label="close" placement='top'>
                    <span color="inherit" onClick={() =>closeCourse()} className='close-course-btn'>
                       <MuiIcons iconName={closeIcon} className='power-icon w-100 h-100' />
                    </span>
                    </Tooltip>
                 <div className='warning-popup'> <CustomSweetAlert warning
                    showCancel
                    show={closeAlert}
                    style={{color:'#000'}} 
                    confirmBtnText={t("CLOSE_CRS")}
                    cancelBtnText={t("CANCEL")}
                    confirmBtnBsStyle="success"
                    title={t("CLOSE_CRS_MSG")}
                    onConfirm={ () => userCfClose() }
                    onCancel={ () => userCancel() }>
                  </CustomSweetAlert>
                   </div>
                    </div> :

                    <div className={`edit-tool-menu`}>
                      <span className={`device-icon ${courseEdit.menuDropDown && editMenuOpened === 'Device' && 'highlight-device'}`} title="View in Devices" onClick={() => handleMenuToggle(deviceRef, resolutionList, 'Device')} ref={deviceRef}>{icon}</span> 
                     {/* {globalStyle?.isLinear  ? */}
                    <span color="inherit" className={`template-icon ${courseEdit.menuDropDown && editMenuOpened === 'Template' && 'highlight-template'}`}title="Add Template" onClick={() => handleMenuToggle(templateRef, templateMenu, 'Template')} ref={templateRef}>
                      <SettingsIcon />
                    </span>
                    {/* :
                    <Button  color="inherit" className={`template-icon ${courseEdit.menuDropDown && editMenuOpened === 'Template' && 'highlight-template'}`} title="Add Template" onClick={() => handleMenuToggle(templateRef, templateMenu, 'Template')} ref={templateRef}>{t("TOPIC")}</Button>}
                     <span className={`device-icon ${courseEdit.menuDropDown && editMenuOpened === 'Device' && 'highlight-device'}`} title="View in Devices" onClick={() => handleMenuToggle(deviceRef, resolutionList, 'Device')} ref={deviceRef}>{icon}</span> */}
                    {/* <span className={`component-icon ${courseEdit.menuDropDown && editMenuOpened === 'Component' && 'highlight-component'}`} title="Template List" onClick={() => handleMenuToggle(compRef, componentList, 'Component')} ref={compRef}><ListAltIcon /></span> */}
                    {/* {!globalStyle?.isLinear && 
                    // <IconButton
                    //     aria-label="account of current user"
                    //     aria-controls="menu-appbar"
                    //     aria-haspopup="true"
                    //     onClick={() => handleMenuToggle(profileRef, profile, 'Profile')} ref={profileRef}
                    //     color="inherit"
                    //     className={`profile-icon ${courseEdit.menuDropDown && editMenuOpened === 'Profile' && 'highlight-profile'}`}
                    // ></IconButton>
                        <MuiIcons 
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={() => handleMenuToggle(profileRef, profile, 'Profile')} ref={profileRef}
                          color="inherit"
                          className={`profile-icon ${courseEdit.menuDropDown && editMenuOpened === 'Profile' && 'highlight-profile'}`}
                          iconName='account_circle' 
                       />
                    } */}
                    <div  className={`user-profile  ${courseEdit.menuDropDown && editMenuOpened === 'Profile' && 'highlight-profile'}`}>
                    {/* {globalStyle?.isLinear && <> */}
                    <span className={`profile-pic`}>
                      <img src={profile_icon} alt='profile' />
                    </span>
                    <div className='user-detail' onClick={() => handleMenuToggle(profileRef, profile, 'Profile')} ref={profileRef}><p className='user-name'>{window.ce?.ceuser?.firstname}</p> <p className='user'>{ACLHelper.getRolName().name }</p></div>
                     <MuiIcons iconName='down_arrow' className={`arrow-dowen`}/>
                     {/* </>} */}
                    <ArrowDropUpIcon ref={arrowRef} className={`arrow-drop-up ${editMenuOpened} ${courseEdit.menuDropDown && 'show-arrow'}`} />
                     {/* <MuiIcons iconName='arrow_drop' className={`arrow-drop-up ${editMenuOpened} ${courseEdit.menuDropDown && ' show-arrow'}`} /> */}
                    <EditMenuDropdown  menuData={menuList} isOpen={courseEdit.menuDropDown} anchor={ref} handleParent={deviceResolution}/>
                    </div>
                    {/* <ArrowDropUpIcon className={`arrow-drop-up ${editMenuOpened} ${courseEdit.menuDropDown && 'show-arrow'}`} />
                    <EditMenuDropdown  menuData={menuList} isOpen={courseEdit.menuDropDown} anchor={ref} handleParent={deviceResolution}/> */}
                    </div>}
                    <CustomPopover 
                          id={popId}
                          anchorEl={anchorEl}
                          className='publish-popover'
                          open={Boolean(anchorEl)}
                          onClose={(e)=>setAnchorEl(null)}
                          message={DataHelper.isEditor() ?
                            "You have made updates to this module. Please ensure that all necessary changes have been incorporated into this version before proceeding with publishing. The publishing process may require some time to complete" : 
                            t("LANGUAGE_POP")}
                          cancelText={t("CANCEL")}
                          confirmText={t("OK")}
                          handleConfirm={isLearner? goLanguage : handlePopover}
                        />
                        
                    <div className='publish-info-popup warning-popup application-info'>

                        <CustomSweetAlert
                         warning
                         showCancel
                         show={isPublish}
                         title={"Publish Version"}
                         style={{color:'#000'}}
                         confirmBtnText={"Confirm"}
                         confirmBtnBsStyle="success"
                         onConfirm={()=>handleIsPublish()}
                         onCancel={()=> handleClose()}
                         children={<span className='info-details'>
                          <p className='msg'>{"Your modifications will reflect to the learners enrolled in the course. Online learners will receive notifications within their course interfaces. Please note that this process may require some time to complete. Click 'Confirm' to publish this version or 'Cancel' if further updates are pending."}</p>
                         </span>} />

                         {/* <CustomSweetAlert
                          warning
                          showCancel
                          show={isCnPublish}
                          style={{color:'#000'}}
                          confirmBtnText={"Confirm"}
                          confirmBtnBsStyle="success"
                          onConfirm={()=>handleIsPublish()}
                          onCancel={()=> setIsCnPublish(false)}
                          children={<span className='info-details'>
                            <p className='msg'>
                              Your modifications will reflect to the learners enrolled in the course. Online learners will receive notifications within their course interfaces. Please note that this process may require some time to complete. Click "Confirm" to publish this version or "Cancel" if further updates are pending.
                            </p>
                          </span>} /> */}

                     </div>
                </Toolbar>
            </AppBar>
      </Box>
      </>
    )
}

NavBar.defaultProps = {
    help: [
      {
        name: 'CourseMap',
        editDrawer: {
          width: 80,
          name: 'Print CourseMap',
          panel: 4
        }
      },
      {
        name: 'Certificate',
        editDrawer: {
          width: 80,
          name: 'Print Certificate',
          panel: 5
        }
      }
      ],
      profile: [
        {
          name: 'Profile'
        }, {
          name: 'Account'
        }
      ],
      templateList: [
        
        { name: "Status",
        editDrawer: {
          width: 80,
          name: 'Status',
          panel: 5
        }}],
      resolutionList: [
        {name: 'Mobile', type: 'Mobile', image: 'phone_icon.png'},
        {name: 'Tablet', type: 'Tablet', image: 'tablet_icon.png'},
        {name: 'Laptop', type: 'Laptop', image: 'laptop_icon.png'},
        {name: 'Desktop', type: 'Desktop', image: 'default_icon.png'}
      ],
      componentList: [
       {name: 'Accordion', image: 'Accordion_comp.png'},
       {name: 'TopicDescription', image: 'Topicdesc_comp.png'},
       {name: 'Video', image: 'Video_comp.png'},
       {name: 'Audio', image: 'Audio_comp.png'},
       {name: 'OrderedList', image: 'Ordered_comp.png'},
       {name: 'UnorderedList', image: 'Unordered_comp.png'},
       {name: 'ImageMapper', image: 'Imagemapping_comp.png'},
       {name: 'Carousels', image: 'Carousel_comp.png'},
       {name: 'Tabs', image: 'Tab_comp.png'}
      ]
}

export default NavBar
