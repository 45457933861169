import React, { useEffect, useRef, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import '../../../assets/scss/audio.scss'
import { PauseOutlined } from "@material-ui/icons";
import PropTypes from 'prop-types'
import ContentTracking from "../../../core/progress/ContentTracking";
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from "../../ui/Instruction/Instruction";
import DataHelper from '../../../utils/DataHelper'
import hotkeys from "hotkeys-js";
import MuiIcons from "../../ui/MuiIcons/MuiIcons";
import ReactDOM from "react-dom";

/**
 * 
 * @param {*} src 
 * @returns the source url of the video
 */

/**
 * 
 * @param {*} readable 
 * @returns content text for the audio
 */

/**
 * 
 * @param {*} handle 
 * @returns handle function to track when audio is completed
 */

/**
 * 
 * @param {*} cename 
 * @returns includes the component name
 */

/**
 * Audio player will play the audio related to the source content
*/

const debouncing = (selector, callback, isLongContent) => {
  const isView = isLongContent || null
  setTimeout(() => {
      let element = document.querySelector(selector);
      let position = element?.getBoundingClientRect();
      const isMobile = (window.matchMedia('max-width: 768px').matches || window.matchMedia('max-height: 450px').matches)
          // checking for partial visibility
      if((position?.top < window.innerHeight && position?.bottom >= 0) && (isMobile || isView)) {
          callback()
      } else {
          // checking whether fully visible
          if(position?.top >= 0 && position?.bottom <= window.innerHeight) {
              callback()
          }
      }
  
  }, 100)
}

const Audio = ({ src, readable, track, handler, cename, instructions, description,isVisited }) => {
  const [audioSrc, setAudioSrc] = useState('');
  const [isPlay, setPlay] = useState(false)
  const player = useRef();
  const [status, setStatus] = useState({completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE})
  const [instruction, setInstruct] = useState(instructions);
  const [isDragEnabled, setDragEnabled] = useState(track?.status === true ? true:false)
  const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
  const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS
  const contentLayout = document.querySelector('.content')

  useEffect(() => {
    if (src) {
      setAudioSrc(src);
    }

    /**
    * Manage the component update 
    * progress logic in this method
    * 
    * Update the view status when ever the user interacts
    * Update the progess status ONLY ONCE, when status completes
    * 
    */
    const updateProgress = (currentItem) => {
      switch (currentItem?.type) {
        case 'PLAYHEAD':
          track.state = { ...track?.state||{}, t: currentItem?.time }
          handler({ type: UPDATE_STATUS, id: track?.id, name: cename })
        break
        case 'END':
          if (track?.status === 0) {
            track.status = 1
            setInstruct({
              ...instruction,
              text: `You have completed ${cename}.`,
              className: 'completed'
            })
            setStatus({completed: true, text:PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
            handler({ type: SAVE_PROGRESS_STATUS, id: track?.id, name: cename })
            console.log("SAVE_PROGRESS_STATUS updateProgress",track)
          }
        break
      }
    }

    /*
    * will update the progress when audio has entirely played 
    */

    let auplayer = player.current.audio.current;
    auplayer.onended = function () {
    if(track)updateProgress({ type: 'END' });
      setPlay(false);
      setDragEnabled(true)
      removeFloatingBtn()
    }
    auplayer.onplaying = (e) => {

      if(track)updateProgress({ type: 'PLAYHEAD', time: auplayer.currentTime })
      const mediaEle = document.querySelectorAll('video, audio')
      for (const media of mediaEle) {
        if (media !== e.target) {
          media.pause()
        }
      }
      floatingBtn()
    }
    auplayer.onpause = (e) => {
      setPlay(false);
      removeFloatingBtn()
    }
  }, [isPlay, src]);

  const updateProgressOnScroll =()=>{
    if(Number(track?.status) !== 1){
      track.status = 1
      setInstruct({
        ...instruction,
        text: `You have completed ${cename}.`,
        className: 'completed'
      })
      setStatus({completed: true, text:PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
      handler({ type: SAVE_PROGRESS_STATUS, id: track?.id, name: cename })
      contentLayout?.removeEventListener('scroll',updateProgressOnScroll)
    } 
  }
  
  useEffect(() => {
    try{
      if(Number(track?.status) !== 1){
          if (isVisited) {
          const handleScroll = () => {
            debouncing('section[name=Audio]', updateProgressOnScroll, true);
          };
          contentLayout?.addEventListener('scroll', handleScroll);
          return () => {
            contentLayout?.removeEventListener('scroll', handleScroll);
          };
        } else{
          contentLayout?.removeEventListener('scroll', updateProgressOnScroll)
          // track.status = track.status !== 1 ? 0 : 1;
          setInstruct({
            ...instruction,
            text: ` Please complete the audio to proceed.`,
            className: 'incompleted'
          })
          setStatus({ completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE })
          handler({ type: SAVE_PROGRESS_STATUS, id: track?.id, name: cename })
          console.log("SAVE_PROGRESS_STATUS useEffect")
        }
      }
  }catch(e){}
  }, [isVisited])


  const playAudio = () => {
    setPlay(true);
    player.current.audio.current.play();
  }

  const pauseAudio = () => {
    setPlay(false);
    player.current.audio.current.pause();
  };
 /**
  * Remove the floating button when audio is paused
  */
  const removeFloatingBtn = React.useCallback(() => {
    let isButtonCheck = document.querySelectorAll(".audio-play-btn");
    if (isButtonCheck[0]) {
      isButtonCheck[0].remove();
    }
  },[])
  
  const handlePauseAudio = React.useCallback(() => {
    setPlay(false);
    player.current.audio.current.pause();
    removeFloatingBtn();
  },[])

/**
 * Add a floating button when audio is played
 */
  const floatingBtn = React.useCallback(() => {
    let controlBar = document.querySelector(".wrapper");
    let isButtonCheck = document.querySelectorAll(".audio-play-btn");
  
    if (controlBar && !isButtonCheck[0] && track?.status) {
      let div = document.createElement("div");
      div.setAttribute("class", "audio-play-btn");
      controlBar.appendChild(div); 
      ReactDOM.render(
           <MuiIcons iconName="Pause"  onClick={() => handlePauseAudio()} color="#fff" />,
        div
      );
    }
  }, []);
  

  const getAudioUrl =(path)=>{
    return DataHelper.getResourcePath(3,path);
  }

  const updateCurrentTime = ( secs ) => {
    try {
      let currentTime = player.current?.audio?.current?.currentTime
      let time = currentTime + secs;
      if ( time < 0 ) {
        time = 0;
      }
      player.current.audio.current.currentTime = time;
    } catch ( e ) {
      console.log( e )
    }
  }

  const rewind = () => {
    updateCurrentTime( -10 )
  }

  const forward = () => {
    updateCurrentTime( 10 )
  }


//   const enablehotKeys = () => {
//     hotkeys('ctrl+alt+7, ctrl+alt+d', function (event, handler) {
//         switch (handler.key) {
//             case 'ctrl+alt+7':
//                 setDragEnabled(true)
//                 console.log('enabled video seek', isDragEnabled)
//                 break;
//             case 'ctrl+alt+d':
//                 setDragEnabled(false)
//                 console.log('disabled video seek', isDragEnabled)
//                 break;
//           default: return 1;
//         }
//       });
// }

// useEffect(() => {
//   enablehotKeys()
//   let controlBar = document.querySelectorAll('.rhap_progress-section')
//   if(isDragEnabled) {
//       controlBar.forEach(el => el.style.pointerEvents = 'initial')
//   } else {
//       controlBar.forEach(el => el.style.pointerEvents = 'none')
//   }


// }, [isDragEnabled])


  return (
    <>
    <Instruction isInstruction={false} completed={(track?.status === 1 || status?.completed)} title={(track?.status === 1 || status?.completed) ? PlayerConstants?.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants?.COMPONENT_CONSTANTS?.STATUS_INCOMPLETE} classText={`${(track?.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} />
    <div className="audio-section-container">
      <section className='audio-section'>
        <div className='audio-container'>
          <div className='control-icon'>
            {/* <span> {!isPlay ? <PlayArrowIcon className="play-pause-icon" onClick={() => playAudio()} /> : <PauseOutlined className="play-pause-icon" onClick={() => pauseAudio()} />}</span> */}
            <span> {!isPlay ? <MuiIcons iconName="play" className="play-pause-icon" onClick={() => playAudio()} /> : <MuiIcons iconName="Pause"  className="play-pause-icon" onClick={() => pauseAudio()} /> }</span>
            <AudioPlayer
              preload='metadata'
              src={getAudioUrl(audioSrc)}
              onPlay={e => console.log("onPlay")}
              autoPlayAfterSrcChange={isPlay}
              ref={player}
              volume={0.5}
              muted={false}
              loop={false}
            />
          </div>
          <div>
            <MuiIcons iconName="Replay10" className="fr-btns back-10"  onClick={ () => rewind()} />
            <MuiIcons iconName="Forward10" className="fr-btns front-10" onClick={ () => forward()} />
            {/* <img alt='backword-icon' className="fr-btns back-10" src={require( `../../../assets/img/back-ten_1.png` ).default} onClick={ () => rewind()} />
            <img alt='forward-icon' className="fr-btns front-10" src={require( `../../../assets/img/forward-ten_1.png` ).default} onClick={ () => forward()} /> */}
          </div>
        </div>
        {(readable || description ) && <div className='readable-text-audio' dangerouslySetInnerHTML={{ __html: description ? description : readable }}></div> }
      </section>
    </div>
    </>
  )

}

Audio.defaultProps = {
  instructions : {
    text: 'Please complete the audio to proceed',
    enabled: true
  }
}

Audio.propTypes = {
  /** Src contains source url of the video */
  src: PropTypes.string.isRequired,
  /** Readable content text for the audio */
  readable: PropTypes.string,
  /** Tracking the component progress */
  track: PropTypes.instanceOf(ContentTracking),
  /** Func description for tracking*/
  handler: PropTypes.func,
  /** Cename will describes the component name */
  cename: PropTypes.string
}

export default Audio;