import * as types from '../reducers/types'



export const globalStyleResponse= (payload) => {
    return {
        type: types.GLOBAL_STYLE_RES,
        payload
    }
}

export const updateGlobalStyle= (payload) => {
    return {
        type: types.UPDATE_GLOBAL_STYLE,
        payload
    }
}

export const globalStyleRequest= (payload) => {
    return {
        type: types.GLOBAL_STYLE_REQ,
        payload
    }
}

export const setComSpacing = (payload) => {
    return {
        type: types.GLOBAL_COMSPACE_STYLE,
        payload
    }
}

export const setGlobalFont = (payload) => {
    return {
        type: types.GLOBAL_FONT,
        payload
    }
}

export const setTopicStyle = (payload) => {
    return {
        type: types.GLOBAL_TOPIC_STYLE,
        payload
    }
}

export const setCourseStructure = (payload) => {
    return {
        type: types.IS_LINEAR,
        payload
    }
}

export const setHideInstructions= (payload) => {
    return {
        type: types.IS_HIDE_INSTRUCTION,
        payload
    }
}

export const updateHomeImage= (payload) => {
    return {
        type: types.HOME_PAGE_IMAGE,
        payload
    }
}


export const updateGlobalLrnSettings= (payload) => {
    return {
        type: types.GLOBAL_LRN_SETTINGS,
        payload
    }
}

export const updateCrsDurationGS=(payload)=>{
    return {
        type: types.UPDATE_CRS_DURATION_GS,
        payload
    }
}