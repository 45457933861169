import { padding } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory,useLocation } from "react-router-dom";
import Reactour from "reactour";
import t from '../../../translation/useTranslate';
import PlayerConstants from "../../../utils/PlayerConstants";
import BuildHelper from "../../../utils/BuildHelper";

const QuickTour = () => {
  const isRtl=(PlayerConstants?.RTL_LANGUAGES?.includes(BuildHelper.defaultLanguage() || 'en_US') && BuildHelper.isLearner());
  const convertToHtml =(htmlstring)=>{
     return (
      <div>
        {/* <button className='btn-tour' style={{border:'none',padding:8}} onClick={() => closeTour()}>Never Show Again</button> */}
        {/* <input type="checkbox"  onClick={() => closeTour()} name="tour" value="Never Show Again"/>
        <label>Never Show Again</label> */}
        <div className="form-check">
          <input  onClick={() => closeTour()}  onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  closeTour(); // Simulate click for Enter
                }
              }} className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
          <label className="form-check-label" for="flexCheckDefault">
            {t("NEVER_AGAIN")}
          </label>
        </div>
        <p dangerouslySetInnerHTML={{ __html: htmlstring }} ></p>
      </div>
    )
    
  }

  let tour = {
    home: [ {
      selector: ".resume-btn",
      content: convertToHtml(`<b>${t('START')}:</b> ${t("Q_CLICK_START")} `),
      action: () => {
        if ( window.location.search === "/" ) {
          history.push( "/course" );
        }

      },

    },
    {
      selector: ".course-overview",
      content: convertToHtml(`<b>${t("COURSE_CONTENTS")}:</b> ${t("Q_VIEW_LEARN")}`)
    },
    {
      selector: ".accordion",
      content: convertToHtml(`<b>${t("CRS_SECTION")}:</b> ${t("Q_TOPIC_PROGRESS")}`)
    },
    {
      selector: ".list-item",
      content: convertToHtml(`<b>${t("TOPIC")}:</b>${t("Q_TOPIC_INFO")}`)
    } ], 
    course: [
   {
      selector: ".next-section",
      content:convertToHtml(`<b>${t("NAV")}:</b> ${t("Q_BUTTON")}`)
    },
    {
      selector: ".header-home",
      content:convertToHtml(t("Q_RET_HOME"))
    },
    {
      selector: ".header-course-map",
      content:convertToHtml(t("Q_PRINT"))
    },
    {
      selector: ".header-certificate",
      content:convertToHtml(t("Q_P_CER_VIEW"))
    } ,
    {
      selector: ".search-icon",
      content:convertToHtml(t("Q_SEARCH"))
    }
  
  ],
  courseTree:[
    {
      selector: ".expand-tree-icon",
      content: convertToHtml(`<b>${t("SIDE_NAV")}:</b> ${t("Q_EXPAND")}`)
    },
    {
      selector: ".tree-structure",
      content: convertToHtml(`<b>${t("COURSE_CONTENTS")}:</b> ${t("Q_VIEW_LEARN")}`)
    },
    {
      selector: ".accordion",
      content: convertToHtml(`<b>${t("CRS_SECTION")}:</b> ${t("Q_TOPIC_PROGRESS")}`)
    },
    {
      selector: ".list-item",
      content: convertToHtml(`<b>${t("TOPIC")}:</b>${t("Q_TOPIC_INFO")}`)
    }, {
      selector: ".comp-layout",
      content: convertToHtml(`<b>${t("LRN")}:</b> ${t("Q_COLLECTION")}`)
    },
    {
      selector: ".circular-progress",
      content:convertToHtml(t("Q_PROGRESS"))
    },
  ]

  }

// CE Notes : Graphically rich Posters to remmber the core concepts in this course.
// Vignettes : Short Vidoes explaining the core concepts in this course with scenarios.
// Updates : Latest updates on the content you learned in this course.
// Logout : Close the course and save your learning progress.
// Status of Learning element : A green tick mark show the status of the learning element as completed. Read the instructional text above each learning element to know the completion criteria.
// Course Overview : Click this burger icon to expand collapse Course overview


  const courseInfo = useSelector( state => state.courseInfo );
  const [ isTourOpen, setTourToggle ] = useState( false )
  const history = useHistory();
  const location = useLocation()
  const [ steps, setTourSteps ] = useState( [] )

  useEffect(() => {
    const currentTour = location?.pathname !== "/" ? { ...tour, course: [...tour.course] } : { ...tour, home: [...tour.home] };

    if (location?.pathname !== "/" && document.querySelector(".supplementary_updates")) {
        currentTour.course = [
            ...currentTour.course,
            {
                selector: ".supplementary_updates",
                content: convertToHtml(t("SUPPLEMENTARY_LEARNING")),
            },
        ];
    }

    if (location?.pathname !== "/" && document.querySelector(".sidebar")) {
      currentTour.course = [
          ...tour.courseTree,
          ...currentTour.course,
      ];
  }
    setTourSteps(location?.pathname !== "/" ? currentTour.course : currentTour.home);
}, [location]);


  useEffect( () => {

    if ( !courseInfo.isLoading ) {
      setTourToggle( !courseInfo.isLoading )
    }

  }, [ courseInfo.isLoading] )






  const closeTour = () => {
    setTourToggle( false );
    if(location.pathname === '/'){
      history.push('/course');
    }
    // window.sessionStorage.setItem('tour', 1)
    if (location?.pathname === '/' ) {
       window.localStorage.setItem('home-tour', 1)
    }else{
      window.localStorage.setItem('course-tour', 1)
    }
  };

  const getCurrentStep = ( curr ) => {
    let completed = curr+1
    console.log( `The current step is ${ completed } ${ steps.length }` );
    if ( Number(completed) === Number(steps.length)) {
      console.log("Steps Completed",completed)
      // setTimeout(()=>{
      //   setTourToggle(false);
      //   window.localStorage.setItem('tour', 1)
      //   history.push('/course');
      // },1000)
      
    }
  }



  return <>
    <Reactour
      steps={ steps }
      isOpen={ isTourOpen ? true : false }
      onRequestClose={ closeTour }
      update={ window?.location?.pathname }
      goToStep={ steps ||[]}
      getCurrentStep={ ( curr ) => getCurrentStep( curr ) }
      className={`${isRtl && 'rtl-tour'}`}
      // showNavigationNumber={false}
      // showNumber={false}
      accentColor={'#13984B'}
    />
  </>
}

export default QuickTour;