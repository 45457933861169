import React,{useEffect,useState} from 'react'
import PropTypes from 'prop-types'
import './searchlist.scss'
import NotesIcon from '@material-ui/icons/Notes';
import { useDispatch, useSelector } from 'react-redux';
import * as courseInfoAction from '../../../redux/actions/courseInfoAction' 
import DataHelper from '../../../utils/DataHelper';
import useToastNotifications from '../../../utils/hooks/useToastNotifications';
import t from '../../../translation/useTranslate';
import * as courseTrackingAction from '../../../redux/actions/courseTrackingAction'

/**
 * 
 * @param {*} query 
 * @returns search query made by the user
 */

/**
 * 
 * @param {*} highlight 
 * @returns function that renders the highlight word
 */

/**
 * 
 * @param {*} flatten 
 * @returns flatten object which will used for easy iteration of course topic
 */

/**
 * 
 * @param {*} courses 
 * @returns array of objects which has each course topic and topiccontent mapped together.
 */

/**
 * SearchList will render the list of topics that we have searched through keyword
*/
const SearchList = ({ query , courses, flatten, highlight}) => {
    const dispatch = useDispatch()
     const {courseInfo, globalStyle, courseTracking} = useSelector( state => state );
      const [isLastTopic,setIsLastTopic] = useState(false);
     const { showError } = useToastNotifications();
    let courseList = []
 
    useEffect( () => {
        const handleToastMessages = () => {
          switch (isLastTopic) {
            case 'last-topic':
                showError(t("LAST_TOPIC_MSG"));
              break;
              case 'strict-nav':
                showError(t("STRICT_TOPIC_MSG"));
                break;
            // Add more cases here as needed
            default:
              break;
          }
          setIsLastTopic(false);
        };
    
        if(isLastTopic) handleToastMessages();
      }, [ isLastTopic ] );

    const filterList = () => {
        if(query.trim() === '' || query === undefined) {
            return courseList = []
        } else {
          const filtered = courses.filter(data => {
            return data.title.toLowerCase().includes(query.trim().toLowerCase()) || data.content.toLowerCase().includes(query.trim().toLowerCase())
          })
          courseList = filtered
        }
    }

    filterList()

    const selectTopic = (topic) => {
        const node=flatten[topic]
        if((globalStyle?.snc && (node?.status===undefined || Number(node?.status)===-1))) {
            setIsLastTopic('strict-nav');
            return;
        }
        if(courseTracking?.courseTracking?.lock_course) return;
        if(node.nodeId === DataHelper.getLastTopic(courseInfo?.flatten)?.nodeId && !DataHelper.isAllTpCompletedExceptLast(courseInfo?.flatten) ){
            setIsLastTopic('last-topic');
            return;
        }
        let tp=DataHelper.getTpWithContent(node);
        dispatch(courseInfoAction.selectedTopic(tp))
        dispatch(courseInfoAction.selectedSection(tp));
        setTimeout(() => {
            highlight(query)
        }, 200)
        dispatch(courseTrackingAction.saveCourseProgress(courseTracking?.courseTracking))
    }
  
    return (
        <div className={`search-list-container ${query !== '' ? 'search-list' : 'no-query'}`}>
            <ul className='search-ul-list'>
                {courseList.length > 0 ? courseList.map((course, index) => <li className='search-content' key={index} onClick={() => selectTopic(course.id)}><button className='non-active-btn w-100'><span><NotesIcon /></span>{course.title}</button></li>) : <p className={`result-not-found ${query === '' ? 'not-show': 'show'}`}>No Result Found</p>}  
            </ul>
        </div>  
    )
}

SearchList.propTypes = {
    /**
     * these are the proptypes object which we are using as props data for this component
    */

    /** Search query for the component */
    query: PropTypes.string,
    /** Courses array data for the displaying list */
    courses: PropTypes.array,
    /** Flatten object which will used for easy iteration of course topic */
    flatten: PropTypes.object,
    /** function that renders the highlight word */
    highlight: PropTypes.func
}

export default SearchList
