import React, { useEffect, useState,useRef } from 'react';
import PropTypes from 'prop-types';
import ContentTracking from "../../../core/progress/ContentTracking";
import './scenarioBlock.scss';
import Button from '@material-ui/core/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import DataHelper from '../../../utils/DataHelper';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';
import ImageTag from '../../ui/ImageTag';
import CommonModal from"../../ui/CommonModal/CommonModal";
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';
import { FormControlLabel} from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import ResizableTextArea from '../../ui/ResizableTextArea/ResizableTextArea';
import Audio from '../Audio/Audio';
import AppButton from '../../ui/AppButton/AppButton';
import t from '../../../translation/useTranslate';
const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS
const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
/* ******slides*******
*
**2 type 
* 0-text-No responses
* 1-dialogue have the responses
* ****
* 
** next_slide -->  2 items there 
* scene-> scene in slide
* slide -> next jump
* ****
* 
** goto --> 3types next,slide,end ( next && slide have Continue-Button )
* 0-next --> auto taking
* 2-end --> end scenario --> try again (only for text mode )
*
*/

const ScenarioBlock = (props) => {
    const {scene,  track, handler, cename, instructions, is_track, title, author, styleObj , ft} = props;
    const [sCIndex, setSCIndex] = useState(0);
    const [sLIndex, setSLIndex] = useState(0);
    const [isDisclaimer, setIsDisclaimer] = useState(false);
    const [isTrams, setIsTrams] = useState(false);
    const [isWarning, setIsWarning] = useState(false);
    const [isPrint, setIsPrint] = useState(false);
    // ScenarioBlock progress track here, we track types 0 and 1
    const [pTrack, setPTrack] = useState('');
    const [isPrintPreview, setIsPrintPreview] = useState(false);
    const { bg_image, background, slide_opacity, sbackgroundColor,character, slides, text_opacity, text_bgColor} = scene[sCIndex || 0];
    const [currentSlide, setCurrentSlide] = useState(slides[0]||{});
    const [selectedResponse, setSelectedResponse] = useState(null);
    const diaRef = useRef(null);
    const [selectedTop, setSelectedTop] = useState('300px');
    //using for last currentSlide
    const endScreen=scene[scene?.length-1||0];
    const [instruction, setInstruct] = useState(instructions);
    const [info, setInfo] = useState(track?.state?.info||{c:"",p:""});
    const [status, setStatus] = useState({completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE});
   // Template Styles
    let btnStyles={fontSize:`${DataHelper.getGlobalFontSize(styleObj?.fnt_s-6||ft-6)}px`};
    // Reset when relevant props change and after start over
    const reset=()=>{
        setSCIndex(0);
        setPTrack('');
        setSLIndex(0);
        setCurrentSlide(slides[0] || endScreen);
        setSelectedResponse(null);
        updateProgress({...track.state,d:"",l:"",status:0});
        setIsDisclaimer(false);
        setIsTrams(false);
        setIsWarning(false);
    }

    const trackStatus=()=>{
        if(Number(track?.state?.l)===-1){
            setCurrentSlide(scene[scene?.length-1]);
            setSLIndex(0);
            setSCIndex(0);
            setInfo({...track?.state?.info||{}})
            return;
        }
        const indexs = track?.state?.l?.split(":")||[0,0];
        const scIndex=Number(indexs[0])
        const slIndex=Number(indexs[1])
        setSCIndex(scIndex);
        setSLIndex(slIndex);
        setCurrentSlide(scene[scIndex||0].slides[slIndex||0])
    }

    useEffect(()=>{
        if(track?.state?.l){
            trackStatus();
        }else{ reset(); }
    },[props,track?.state]);


    let completedSlide = {
        id: '-1',title: 'Scenario Completed !',next_slide: { scene: scene[0].id, slide: scene[0].slides[0].id },
        goTo: 1,type: 0,responses: [],bg_image:currentSlide?.bg_image ||bg_image|| '',character:character?.src||''
    }

    const getImageUrl = (img) => {
        try{
          return DataHelper.getResourcePath(0, img);
        }catch(e){}
    }

    // const imgStyleObj = {
    //     width: "100%",
    //     height: "100%",
    //     background: ` url(${getImageUrl(currentSlide?.bg_image || bg_image)}) ${currentSlide?.background || background || '50% 50% / cover no-repeat'} `,
    // }

    const gotoText = () => {
        return (currentSlide?.id == '-1') ? <span><ReplayIcon />{t("START_OVER")}</span> : t("CONTINUE");
    }


     /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the progress status ONLY ONCE, when status completes
     * 
     * state: {
     * "l": "0:4",
     * "d": "0:0:0|",
     * "info": {
     * "c": "",
     * "p": "",
     * "d": "January 29, 2024"
     * }
     * }
     * 
     * d - keeping dialogue progress ---------  
     ********    0        :     1      :         1            | 
     ********  sceneIndex : slideIndex : clicked response index
     *
     * l - last visited 
     * info - keeping ind {
     * c - company
     * p - productieus
     * d - date
     * n - notes
     * }
     * 
     * 
     */
    const updateProgress = (progress) => {
    //  if(!track.status){
        track.state = { ...track.state,l:progress?.l||`${sCIndex}:${sLIndex}`, d:progress?.d?.trim()||"", info:progress?.info||{}};
        /**
         * Progress updated as and when its completed
         */
        if(progress?.status === 1 && !track.status){
            track.status = 1;
            track.state = { ...track.state,l:`-1`, d:progress?.d?.trim()||"",info:progress?.info||{}};
            setInstruct({
                ...instruction,
                text: `You have completed ${cename}, you can proceed to next section`,
                className: 'completed'
            })
            setStatus({completed: true, text:PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
            handler({ type: SAVE_PROGRESS_STATUS, id:track.id, name: cename })
         
        }
         /**
         * If anything to be intimated always
         * call this
         */
         handler({ type: UPDATE_STATUS, id:track.id, name: cename})
    //  }
    }


// function to handle the "Next" button click
const buttonNextJump = (obj) => {
    try {
        // reset if obj.id is -1
        if (obj?.id === -1) {
            reset();
            return;
        };

        // track progress by updating pTrack
        setPTrack(`${pTrack || ""}${sCIndex}:${sLIndex}|`);

        const { next_slide, goTo, type } = obj;

        // update completedSlide for the last scene object
        completedSlide = endScreen?.id === -1 ?
            { ...endScreen, bg_image: endScreen?.bg_image || completedSlide?.bg_image || "" } : completedSlide;

        if (next_slide === null && goTo === 0) {
            // move to the next slide
            let next = sLIndex + 1;
            if (next < slides?.length) {
                setCurrentSlide(slides[next]);
                setSLIndex(next);
                updateProgress({ ...track.state, l: `${sCIndex}:${next}` });
                setSelectedResponse(null);
            }else if(sCIndex < scene?.length-2){
                let nextSc=sCIndex+1
                setSCIndex(nextSc);
                setSLIndex(0);
                setCurrentSlide(scene[nextSc]?.slides[0]);
                setSelectedResponse(null);
                updateProgress({ ...track.state, l: `${nextSc}:${0}` })
            }else {
                // move to the completedSlide if at the last slide
                setCurrentSlide(completedSlide);
                setSLIndex(0);
                setSCIndex(0);
                updateProgress({ ...track.state, status: 1, l: "-1" });
            }
        } else if (goTo === 2) {
            setSCIndex(0);
            setSLIndex(0);
            setCurrentSlide(completedSlide);
            if (Number(type) === 0) {
                // update dialogue progress
                const d = `${track?.state?.d || ""}${sCIndex}:${sLIndex}|`;
                updateProgress({ ...track?.state, d: d?.trim() || "", status: 1, l: "-1" });
            } else {
                // update progress completed
                updateProgress({ ...track.state, status: 1, l: "-1" });
            }
            setSelectedResponse(null);
        } else if (goTo === 1) {
            // go to a specific scene and slide
            let sceneIdx = scene?.findIndex((el) => el?.id === next_slide?.scene);
            let slidesIdx = scene[sceneIdx]?.slides?.findIndex((el) => el?.id === next_slide?.slide);
            if (sceneIdx !== -1 && slidesIdx !== -1) {
                setSCIndex(sceneIdx);
                setSLIndex(slidesIdx);
                setCurrentSlide(scene[sceneIdx]?.slides[slidesIdx]);
                setSelectedResponse(null);
                updateProgress({ ...track.state, l: `${sceneIdx}:${slidesIdx}` })
            }
        }
        setSelectedTop('300px');
    } catch (e) { }
}

    const responseClick = (obj) => {
        // set the selected response
        setSelectedResponse(obj);

        // check if the action 'continue','goTo' is end, and tracking is enabled
        if (obj?.action === "continue" && (!scene[sCIndex]?.slides[sLIndex]?.ig_track || obj?.goTo === 2) && is_track) {
            // find the index of the response in the current slide's responses array
            const index = scene[sCIndex]?.slides[sLIndex]?.responses?.findIndex((e) => String(e?.id) === String(obj?.id));

            // update the progress scene slide and response 
            const d = `${track?.state?.d || ""}${sCIndex}:${sLIndex}:${index}|`;
            updateProgress({ ...track?.state, d: d?.trim() || "" });
        }
        
        //selected top after 200 milliseconds
        setTimeout(() => {
            setSelectedTop('-25px');
        }, 200);
    }


    const getTopPX = (type) => {
        if (type === 'dialogue') {
            return selectedResponse === null ? '120px' : '-203px'
        } else if (type === 'feedBack') {
            return '140px'
        }
        return
    }

    const selectedResponseTop = (id) => {
        if (selectedResponse?.id === id) {
            setTimeout(() => {
                setCurrentSlide({ ...currentSlide, responses: [] });
            }, 400);
            return '-120px'
        }
        return '10px'
    }

    const tryAgain = () => {
        setCurrentSlide(slides[sLIndex]);
        setSelectedResponse(null)
        setSelectedTop('300px')
    }

    const handleResponseClick = (type) => {
        if (type === 'Try Again') tryAgain();
        else buttonNextJump(selectedResponse);
    }

    const continueBtn = (text) => {
        return <>
            <AppButton theme={"primary1"} size="medium"  style={btnStyles} onClick={() => buttonNextJump(currentSlide)} >{text}</AppButton>
        </>
    }

    const getTextAreaStyle=()=>{
        const textBg=styleObj?.t_bg||currentSlide?.text_bgColor||text_bgColor||'#fff';
        const textOp=currentSlide?.text_opacity||text_opacity||'';
        let backgroundColor = `${String(textBg).includes("rgba") ? textBg : `rgba(${hex_to_RGB(textBg)}, ${textOp || 1})` || '#fff'}`;
        return {backgroundColor} || {}
    }
    const getAreaStyle=()=>{
        const slideBg=styleObj?.sb_bg||currentSlide?.sbackgroundColor || sbackgroundColor || 'rgba(7, 13, 89, 0.8)';
        const textOp=currentSlide?.slide_opacity||slide_opacity || '0.1';
        let backgroundColor=`rgba(${hex_to_RGB(slideBg)},${textOp || 1})`||'#fff'
        return {backgroundColor,zIndex:!isPrint ? 99 :999999999} || {}
    }

    const hex_to_RGB = (hex = "#ffffff") => {
        try{
            if (hex) {
                var m = hex != "#fff" ? hex?.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i) : "#ffffff".match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
                return  `${parseInt(m[1], 16)}, ${parseInt(m[2], 16)}, ${parseInt(m[3], 16)}`;
            }
        }catch(e){return '#000'}
    }

    const updateInfo=(e)=>{
        setInfo({...info,[e.target.name]:e?.target?.value||""});
        e.stopPropagation();
    }
    const handleModel=(e)=>{
        setZIndex('.sidebar','9');
        setZIndex('.expand-tree-icon','999');
        setIsPrint(false);
        updateProgress({...track?.state,l:"-1",info:{...info||{},d:(DataHelper?.MonthDayYearFormat(new Date())||"")}});
        setIsPrintPreview(false)
        e.stopPropagation();
    }

    const getDate=()=>{
        const today = new Date();
        const date = today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear();
        return date
    }

    const setZIndex = (selector, zIndex) => {
        const element = document.querySelector(selector);
        if (element) {
          element.style.zIndex = zIndex;
        }
      };

    const handlePrint=()=>{
        setIsPrint(!isPrint);
        setZIndex('.sidebar','-1');
        setZIndex('.expand-tree-icon','-1');
    }

    /*
     ******** 0 : 1 : 1 |
     ******** sceneIndex : slideIndex : clicked response index
     * For every click, we track the last record.
     */
    const handleBack = () => {
        try {
            // check if there is a track with recorded state
            function updateTrackInfo(scI, slI) {
                if (track?.state?.d?.length > 0 && is_track) {
                    // extract and manipulate track information
                    const trackString = track.state.d || "";
                    const parts = trackString?.split('|')?.filter(part => part?.trim() !== '');
                    const lastItem = parts[parts?.length - 1];
                    const [sc, sl] = lastItem?.trim()?.split(":")?.map(Number) || [0, 0];

                    // track corresponds to the current slide
                    if (Number(sc) === scI && Number(sl) === slI) {
                        // update track without the last item
                        const currentTrack = parts.slice(0, -1).join('|');
                        updateProgress({ ...track?.state, d: `${currentTrack}|`, l: `${sce}:${sli}` });
                    }
                }
            }

            // reset selected response
            setSelectedResponse(null);

            // response is percent then tack will check 
            if (selectedResponse) updateTrackInfo(sCIndex, sLIndex);

            // extract and manipulate progress information
            const pParts = pTrack?.split('|')?.filter(part => part?.trim() !== '');
            const lastItem = pParts[pParts?.length - 1];
            const [sce, sli] = (lastItem?.trim()?.split(":").map(Number)) || [0, 0];
            const currentPTrack = pParts.slice(0, -1).join('|');

            // update state
            setSCIndex(sce);
            setSLIndex(sli);
            setCurrentSlide(scene[sce]?.slides[sli]);
            setPTrack(`${currentPTrack}|`)
            updateTrackInfo(sce, sli);
        } catch (e) {

        }
    };
    

    const printAction = () => {
        try {
            return <div >
                <CommonModal
                    isOpen={track?.state?.d?.length > 1||false}
                    onClose={(e) => handleModel(e)}
                    bgColor={"#ffff"}
                    content={
                        <div className="inner-model-sc-content">
                            <ReactToPrint
                                trigger={() => <div className='header-section'> <button className="print pw-btn" onClick={(e)=>setIsPrintPreview(true)} ><><PrintIcon />Print</></button> </div>}
                                content={() => diaRef?.current}
                                onBeforeGetContent={() => new Promise((resolve) => setTimeout(resolve, 1000))}
                            />
                            <div className='print-vertificate-sc-main'  ref={diaRef}>
                            <div className='print-sc-content'>
                            <h2 className='topic-name'>{title||""}</h2>
                            <br/>
                            <div className='info'>
                            <p><b>{t("COMPANY")}</b> : {!isPrintPreview ? <ResizableTextArea value={info?.c||""}  minRows={1}  name="c" onChange={(e)=>updateInfo(e)} placeholder=" Enter the company name" />  : info?.c||"" }</p>
                            <p><b>{t("PRODUCT")}</b> : {!isPrintPreview ? <ResizableTextArea value={info?.p||""}  minRows={1}  name="p" onChange={(e)=>updateInfo(e)} placeholder=" Enter the product name" />  : info?.p||"" }</p>
                            <p><b>{t("ANALYSIS_PERFORMED_BY")}</b> :  {author||""}</p>
                            <p><b>{t("DATE")}</b> : {track?.state?.info?.d||DataHelper?.MonthDayYearFormat(getDate())||""}</p>
                            <p><b>{t("NOTE")}</b> : {!isPrintPreview ? <ResizableTextArea value={info?.n||""}  minRows={3}  name="n" onChange={(e)=>updateInfo(e)} placeholder=" Enter the note"  />  : info?.n||"" }</p>
                            </div>
                            <br/>
                            <ol>
                            {track?.state?.d?.length > 1 && track?.state?.d?.trim()?.split("|")?.map((e, index) => {
                                const el = e?.trim()?.split(":") || "";
                                const element =scene[el[0]]?.slides[el[1]]||"";
                                return e?.length > 0 && 
                                    <li key={index} className={`${!el[2] && "last-dialogs" }`}>
                                        <p dangerouslySetInnerHTML={{ __html: element?.description }} /> 
                                      {el[2] &&  <p className='answer' dangerouslySetInnerHTML={{ __html: element?.responses[el[2]]?.description }} />}
                                       {((track?.state?.d?.trim()?.split("|")?.length-2) ===index && element?.responses[el[2]]?.goTo === 2) && <div className='feedback-box'> <p className='feedback' dangerouslySetInnerHTML={{ __html: element?.responses[el[2]]?.feedback }} /></div> }
                                       { (track?.state?.d?.trim()?.split("|")?.length-2) !==index && <hr/>}
                                    </li>
                            })}</ol>
                            <h3 className='disclaimer-title'> {t("DISCLAIMER")}</h3>
                            {disclaimerText()}
                            </div>
                            </div>
                        </div>
                    }
                    width="65%"
                    height="80%"
                    shadow="none"
                /> </div>
        } catch (e) {
        }
    }

    const disclaimerText=()=>{
        return <div className={`disclaimer-text`}>
               <p>{t("DISCLAIMER_MSG1")}</p>
               <p>{t("DISCLAIMER_MSG2")}</p>
               <p>{t("DISCLAIMER_MSG3")}</p>
             </div>
    }

    const handleAccept=()=>{
       if(isTrams)setIsDisclaimer(true);
       setIsWarning(true);
    }

    const handleCheck=(e)=>{
        setIsTrams(e?.target?.checked)
        setIsWarning(false);
    }


    const disclaimer=()=>{
        return <div>
             <CommonModal
                    isOpen={track?.state?.d?.length > 1||false}
                    onClose={(e) => handleModel(e)}
                    title="Disclaimer"
                    buttons={[{
                        text:t("DISCLAIMER"),
                        func:(e)=>{handleModel(e)}
                        
                    },{
                        text:t("ASSESSMENT"),
                        func:()=>handleAccept(),
                        color:isTrams ?  "#fff" : "#000",
                        bgColor:isTrams ?  "#13984b" : "#a5a5a5",
                    }
                ]}
                    bgColor={"#ffff"}
                    content={
                        <>
                         <div className="inner-model-sc-content">
                            <div className={`${(isWarning && !isDisclaimer) && "warning"} info`}>
                            {disclaimerText()}
                            <p>
                            <FormControlLabel
                            className='ck-check-box'
                            control={<Checkbox checked={isTrams} name="n" onChange={(e) =>handleCheck(e)} />}
                            label={t("DISCLAIMER_CHECK_MSG")}
                        />
                            </p>
                            </div>
                        </div>
                        </>
                    }
                    width="60%"
                    height="60%"
                    shadow="none"
                />
        </div>
    }

    const customAudio=(src)=>{
        try{
            return !(src===-1 || !src) && <div className='audio-player'>
                <Audio src={src} />
            </div>
        }catch(e){ }
    }


    const dialogueUI=()=>{
        return<>
         <div className='scenario-block-dialogues'>
                            <div className='dialogues-inner'>
                                <div className={`scenario-block-dialogues-top`}>
                                    <div className='top-inner'>
                                        {selectedResponse !== null && <div className='scenario-block-dialogue-appear-don'>
                                            <div className='dialogue-body' style={{ top: selectedTop }}>
                                                <div className='dialogue' style={{...colorStyle }}  >
                                                    <div className='response-text text-content' dangerouslySetInnerHTML={{ __html: selectedResponse?.description }}  ></div>
                                                </div>
                                            </div>
                                        </div>}
                                        {/* Response Text */}
                                        <div className='scenario-block-dialogue'>
                                            <div className='dialogue-body' style={{ top: getTopPX('dialogue'), ...colorStyle }}>
                                                {customAudio(currentSlide?.audio)}
                                                <div className='dialogue-content' dangerouslySetInnerHTML={{ __html: currentSlide.description }}  ></div>
                                            </div>
                                            {selectedResponse?.feedback && <div className='dialogue-body' style={{ top: getTopPX('feedBack'), ...colorStyle}}>
                                                <div className='text-content' dangerouslySetInnerHTML={{ __html: selectedResponse?.feedback }}  ></div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='scenario-block-dialogues-bottom'>
                                    <div className='bottom-inner'> 
                                        <div className='scenario-block-dialogues-responses'>
                                            {currentSlide?.responses?.length > 0 && selectedResponse == null && getResponse()?.map((res, index) => {
                                                return <div key={index} className={`response`} style={{ top: selectedResponseTop(res?.id) }}>
                                                    <button className='response-text text-content' style={{...colorStyle,maxHeight:maxRHeight}} onClick={() => responseClick(res)} dangerouslySetInnerHTML={{ __html: res?.description }}  ></button>
                                                </div>
                                            })}
                                            {selectedResponse !== null && <div className='response' >
                                                <button className='response-text action' style={{...colorStyle,maxHeight:maxRHeight}} onClick={() => handleResponseClick(selectedResponse?.action)}  >{selectedResponse?.action === 'Try Again' && <ReplayIcon />}{selectedResponse?.action}</button>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        </>
    }

    
    const tsKey={
        'continue':"CONTINUE",
        'Try Again':"TRY_AGAIN",
    }

    const transparentUI = () => {
        return <>
            <div className='transparent-block'>
                {/* transparent template template-block */}
                <div className='transparent-inner'>
                    <div className='question-cnt'>
                        <p className='heading'>{currentSlide?.header}</p>
                        <div className='dialogue-body' style={{ ...colorStyle }}>
                            {customAudio(currentSlide?.audio)}
                            <div className='dialogue-content' dangerouslySetInnerHTML={{ __html: `<span class=answer-op >${t("QUESTION")}. ${currentSlide?.description}</span>` }}  ></div>
                        </div>
                    </div>
                    {/* response options */}
                    <div className='option-cnt'>
                        <ul className='option-ul'>
                            {currentSlide?.responses?.length > 0 && currentSlide?.responses?.map((res, index) => {
                                return <li key={index} className={`response ${selectedResponse?.id===res?.id && ' response-clicked'} `}>
                                    <div className='response-text text-content' style={{ ...colorStyle }} onClick={() => responseClick(res)} dangerouslySetInnerHTML={{ __html: `<span class=answer-op >${t("ANSWER")} ${index + 1}. ${res?.description}</span>` }}  ></div>
                                </li>
                            })}
                        </ul>
                    </div>
                    {/* response feedback */}
                    <div className='response-feedback'>
                        {selectedResponse?.feedback && <div className='dialogue-body' style={{ colorStyle }}>
                            <div className='text-content' dangerouslySetInnerHTML={{ __html: selectedResponse?.feedback }}  ></div>
                        </div>}
                        <div className='action-btns'>
                            {(!(sCIndex === 0 && sLIndex === 0) && currentSlide?.type !== 0) && <AppButton theme={"primary1"} size="medium" style={btnStyles}  onClick={() => handleBack()} >{t("PREVIOUS")}</AppButton>}
                            {selectedResponse !== null && <AppButton size="medium"  theme={"primary1"} style={btnStyles} onClick={() => handleResponseClick(selectedResponse?.action)}  >{selectedResponse?.action === 'Try Again' && <ReplayIcon />}{t(tsKey[selectedResponse?.action])}</AppButton>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    }


    let colorStyle={color:`${styleObj?.t_c||'#000'}`,backgroundColor:`${styleObj?.dia_bg||'#fff'}`,border:styleObj?.dia_bdr||""}
    let maxRHeight= currentSlide?.responses?.length < 4 ? "91px": "84px";
    let isTemplate=Number(styleObj?.ui)===2;

    const getResponse=()=>{
        if(isTemplate!==2 && currentSlide?.responses.length > 4){
            const updatedResponse = currentSlide?.responses?.slice(0, 4);
            setCurrentSlide({...currentSlide,responses:updatedResponse})
            return updatedResponse
        }
        return currentSlide?.responses
    }
    
    return (
     <>
        <Instruction isInstruction={instruction?.enabled} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text}/>
        <div className='scenario-block'  style={{'--fixed-font-size':`${DataHelper?.getGlobalFontSize(styleObj?.fnt_s||ft)}`,'--fixed-font-weight':`${styleObj?.fw||'normal'}`,...colorStyle, fontSize:`${DataHelper?.getGlobalFontSize(styleObj?.fnt_s||ft)}px`}} >
            <div className='scenario-block-background'>
                <ImageTag img={currentSlide?.bg_image || bg_image} position={currentSlide?.styles?.position || currentSlide?.background || background || "50% 50% / cover no-repeat"} opacity={currentSlide?.slide_opacity||slide_opacity || '0.1'} bgColor={styleObj?.sb_bg||currentSlide?.styles?.color || sbackgroundColor || ""}  nonAspect={true} />
                {/* <div className='back_image' style={imgStyleObj}></div> */}
            </div>
           {(!(sCIndex === 0 && sLIndex === 0) && currentSlide?.type !== 0) && !isTemplate && <AppButton  style={btnStyles} size="medium" theme={"primary1"} className='scenario-continue-btn back-btn' onClick={() => handleBack()} >{t("PREVIOUS")}</AppButton>}
            {/* <div className="background-color" style={{backgroundColor:`rgba(${0},${0},${0},${0.6})`}}></div> */}
            <div className={`scenario-block-inner ${(isTemplate && currentSlide?.type === 0) && "template-transparent"}`} style={getAreaStyle()|| {}}>
                {/* Character */}
                {((currentSlide?.character || character?.src !=="*" ) && currentSlide.type !== 0 && character?.isCharacter) && <div className='scenario-block-character'>
                    <div className="scenario-block-character-inner">
                      <img alt="" role="img" src={getImageUrl(currentSlide?.character || character?.src||"")} />
                    </div>
                </div>}
                {/*Text*/}
                {currentSlide?.type === 0 ? <>
                 <div className= {`scenario-block-text scenario-block-content ${!((currentSlide?.emotion !== null || currentSlide.type !== 0) && character?.isCharacter) && "no-character"}`}>
                    <div className='scenario-block-text-inner' style={getTextAreaStyle()|| {}}>
                        <div className='scenario-head'>
                            <span className='scenario-head-h2' dangerouslySetInnerHTML={{ __html:currentSlide?.title }}></span>
                        </div>
                        {customAudio(currentSlide?.audio)}
                        <div className={`scenario-description ${(isTemplate && currentSlide.type !== 0)  && "full-scroll"}`}>
                            <div className='scenario-description-text' dangerouslySetInnerHTML={{ __html: currentSlide?.description }}  ></div>
                        </div>
                        <div className='scenario-description final-text-acton'>
                            {/* <div> */}
                            {!(sCIndex === 0 && sLIndex === 0) && <AppButton size="medium" style={btnStyles} theme={"primary1"} onClick={() => handleBack()} >{t("PREVIOUS")}</AppButton>}
                                {continueBtn(gotoText())}

                                {(currentSlide?.id == '-1' && is_track && (track?.state?.d?.length||0) > 0) && <div className='final-action'>{!isPrint?<><AppButton style={btnStyles} size="medium" theme={"primary1"} className='btn scenario-continue-btn' onClick={() =>handlePrint()} >{t("VIEWANALYSIS")}</AppButton> </> : <>{isDisclaimer ? printAction() :disclaimer()}</> } </div>}

                            {/* </div> */}
                            {/* {(currentSlide?.id == '-1' && is_track && (track?.state?.d?.length||0) > 0) && <div className='final-action'>{!isPrint?<><PrintIcon className="print-icon1" onClick={(e)=>setIsPrint(!isPrint)} /> </> : <>{printAction()}</> } </div>} */}
                        </div>
                    </div>
                </div></>
                    : <>
                        {/* dialogues */}
                        {!isTemplate ? dialogueUI() : transparentUI()}
                    </>}
            </div>

        </div>
    </>
    )
}


ScenarioBlock.propTypes = {
    /** scene used for manage all dialogue,images && text */
    scene: PropTypes.array,
    /** Tracking the component progress */
    track: PropTypes.instanceOf(ContentTracking),
    /** Func description for tracking*/
    handler: PropTypes.func
}

export default ScenarioBlock