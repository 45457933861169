import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

import Pagination from '@mui/material/Pagination';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types'
import ContentTracking from "../../../core/progress/ContentTracking";
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';
import Congratulate from '../../../assets/img/Congratulate.png';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Close from '@material-ui/icons/Close';
import Confetti from "react-confetti";
import './Quizzes.scss';
import ReplayIcon from '@mui/icons-material/Replay';
import DataHelper from '../../../utils/DataHelper';
import AppButton from "../../ui/AppButton/AppButton"
import t from '../../../translation/useTranslate';
import CustomSweetAlert from '../../ui/CustomSweetAlert/CustomSweetAlert';
import BuildHelper from '../../../utils/BuildHelper';
function Quizzes(props) {
  const [questions, setQuestions] = useState([])
  const [activeStep, setActiveStep] = useState(0);
  const [selectItem, setSelectItem] = useState([]);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  // const [isAnalysis, setIsAnalysis] = useState(false);
  // const [finalAnswer, setFinalAnswer] = useState([]);
  // const maxSteps = questions?.length;
  const [isRetake, setIsRetake] = useState(false);
  const [isView, setIsView] = useState(false);
  const [instruction, setInstruct] = useState(props.instructions);
  const [status, setStatus] = useState({ completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE })
  const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
  const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS
  const [stopConfetti, setStopConfetti] = useState(false);
  const [isConfetti, setIsConfetti] = useState(false);
  const [isFeedBack, setIsFeedBack] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [completedIDX, setCompletedIDX] = useState(0);
  const [retakeProgress, setRetakeProgress] = useState([]);




  
/**
 * Display the pre-test feature based on global settings and home.js.
 */
 const isPreTest=props?.isPreTest


  /*
   *
   * autoCheck -- auto check and show the correct answer check
   *
   * quizTry - single quiz try again count
   * 
   * isQuizTry -  single quiz try  active or not
   * 
  */
  const [configure, setConfigure] = useState({quizTry:1});
  const { cename, handler, track, items, max_select, randomization, globalSettings, config, resetCourse, sendQuizReport, reDirect, lockCourse} = props
  // const { ceAssetsInfo} = useSelector(state => state);


const checkResult = () => {
  try{
    // If accepting only correct answers is disabled, always return true
    if (!config?.config?.acpt_only_corct_ans?.value || isView || configure?.autoCheck) {
      return true;
    }

    // Increment quiz try count and return true if the maximum try count is reached
    if (Number(config?.config?.ques_tcnt?.value) === Number(configure?.quizTry)) {
      return true;
    }

    let currentQuestion ={...questions[activeStep]}||{};
    const selectedAnswer =selectItem[activeStep]||{};
    // Adjusting for true/false choice question type if necessary
    if (currentQuestion?.qtype === 0) {
        currentQuestion.answers[0] = Math.max(currentQuestion?.answers[0]-1, 0);
      }

    // Check if the selected answer matches the correct answer
    const correctAnswers = currentQuestion.answers.sort().join(", ");
    const selectedAnswers = selectedAnswer?.sort()?.join(", ");

    const isCorrect = JSON.stringify(correctAnswers) === JSON.stringify(selectedAnswers);

    if (!isCorrect) {
      // Increment quiz try count and set quiz try again to true
      setConfigure(prevConfig => ({ ...prevConfig, quizTry: (prevConfig?.quizTry|| 1)+ 1, isQuizTry: true }));
      return false; 
    }

  return true;
 }catch(e){}
   
};


  const handleTryAgain = () => {
    selectItem[activeStep]=null;
    setConfigure({...configure,isQuizTry:false});
  };

  const handleNext = () => {
    try {
      if(completedIDX > activeStep){
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        return;
      }
      const currentQuestion = questions[activeStep];
      const allQuestionsAnswered = selectItem?.length === questions?.length;
      const isLastQuestion = activeStep === questions?.length - 1;

  
      // Show feedback if available and not already shown
      if (currentQuestion?.hasfeedback && !isFeedBack && !isView) {
        setIsFeedBack(true);
        return;
      }
  
      setIsFeedBack(false); // Reset feedback state

      if(allQuestionsAnswered&& Number(track?.status)===1) setShowScore(true);
      
      // Auto check validation and attempt count
      const shouldAutoCheck = (Number(configure?.quizTry) === Number(config?.config?.correct_ans?.value)
        && !configure?.autoCheck
        && config?.config?.ans_dtype?.value
        && !isView);
  
      if (shouldAutoCheck) {
        setIsFeedBack(true);
        let currentQuestion = questions[activeStep];
        if (currentQuestion?.qtype === 0) {
             currentQuestion.answers[0] = Math.max(currentQuestion?.answers[0]-1, 0);
         }
        selectItem[activeStep]=currentQuestion?.answers;
        setSelectItem(selectItem);
        setConfigure(prevConfig => ({ ...prevConfig, quizTry:1, autoCheck: true }));
        return;
      }
  
      const isCorrect = checkResult();
  
      // Move to the next question if correct or if last question answered
      if (isCorrect || (allQuestionsAnswered && isCorrect)) {
        if (!isLastQuestion) {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          setCompletedIDX(prevActiveStep => prevActiveStep + 1)
        }
        setConfigure(prevConfig => ({ ...prevConfig, quizTry:1, autoCheck: false }));
      }
  
      // Activate scoreboard if all questions answered and correct
      if (allQuestionsAnswered && isCorrect) {
        getResult(selectItem);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      return false;
    }
  };
  


  const handleBack = () => {
    // min value 0
    setActiveStep(Math.max(activeStep -1, 0));
    setIsFeedBack(false);
  };

  const shuffle = (array) => {
    try {
      if (!array || !array.length) {
        throw new Error('Invalid array');
    }
      let currentIndex = array?.length, randomIndex;
      // While there remain elements to shuffle.
      while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math?.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
      return array;
    } catch (e) {
      return []
    }
  }

// Check if old values are present or not
  const valuePresentCheck=(val,alt)=>{
    try{
        if(val || val===false){
          return  Number(val) 
        }
        return Number(alt) 
    }catch(e){
      return false
    }
   }

  useEffect(() => {
    try {
      if(!isView){
        const isCompleted=track?.status === 1 || status?.completed || track?.state?.b===1 || track?.state?.b===2;
        const maxQuiz=valuePresentCheck(config?.config?.sel_ques?.value,max_select) <= items?.length ? valuePresentCheck(config?.config?.sel_ques?.value,max_select): items?.length;
        let cloneData=DataHelper?.deepClone(items);
        if(!isCompleted || Object.keys(track?.state)?.length < 1) setQuestions([...cloneData] || []);
        if (valuePresentCheck(config?.config?.rand_ques?.value,randomization) && !(isCompleted) && !isView && BuildHelper?.isConfig()) {
          let quizList=cloneData;
          let splitArray =[...shuffle(quizList)];
          if (Number(maxQuiz) <= props?.items?.length) splitArray = splitArray.slice(0, (maxQuiz||items?.length));
          setQuestions([...splitArray] || []);
        }
      }
    } catch (quiz_err) {
      console.log(quiz_err,"useEffect quiz issue")
      // setQuestions((prevQuestions) => (Array.isArray(prevQuestions ) ? prevQuestions : []));
    }
  }, [config, items, showScore, isRetake])

  // useEffect(() => {
  //   if (questions.length > 0) {
  //     let tempFinalAns = []
  //     questions.forEach(element => {
  //       // let tempAns = []
  //       element?.qtype && tempFinalAns.push(element?.qtype === 2 ? element?.options.filter((item, index) => element?.tem?.answers?.filter((e) => e == index).length >= 1 ) : element?.options[element?.answers[0]])

  //     });
  //     // setFinalAnswer([...tempFinalAns])
  //   }
  // }, [questions])


  const onHandleSelect = (e, type) => {
    try{
      let value=Number(e.target.value)
      if(!isView && !isFeedBack){
        if (type === 2) {
          let temp = [...selectItem];
          if(temp[activeStep]){
            if(temp[activeStep].some(e=>e==value)) temp[activeStep] = temp[activeStep].filter(e=> e != value)
            else  temp[activeStep].push(value)
          }else temp[activeStep] = [value]
          setSelectItem([...temp]);
        }
        else {
          let temp = [...selectItem];
          temp[activeStep] = [value]
          setSelectItem([...temp]);
        }
      }
      // updateProgress({type:'UPDATE'}); 
    }catch(e){}
  }

  const getFeedBackResult= (index)=>{
   try{
    // if auto check true returning true
    if(configure?.autoCheck) return true
     const activeQuiz={...questions[index]}
      let selectedValues =selectItem[index].map(e=>Number(e)).sort();
      let answerValues =activeQuiz?.answers.map(e=>Number(e)).sort();
      if(activeQuiz?.qtype == 0)answerValues[0]=Math.max(answerValues[0]-1,0);
      if (JSON.stringify(selectedValues) == JSON.stringify(answerValues)) {
       return true
      }
      return false
    }catch(e){}
  }
  
 // convert string to number array
  const getStringToArray = (data) => {
    const rcoaValues = (data?.value || '')?.trim()?.split(',')?.filter(Boolean)?.map(e => Number(e));
    return rcoaValues || [];
  }

  const numberToAlphabet=(number)=> {
   try{
    const baseCharCode = 'a'?.charCodeAt(0) - 1; // Get the character code for 'a' and subtract 1
    const alphabetPosition = number % 26 || 26; // Map 0 to 'z' (26th position)
    const charCode = baseCharCode + alphabetPosition; // Calculate the character code
    return String?.fromCharCode(charCode);
   }catch(e){
    console.error("quiz",e);
   }
}
const createIndexMap = (questions, items) => {
  if (!items || !questions) {
      throw new Error('Invalid input');
  }

  // Step 1: Create a map from _id to index for items
  const itemIndexMap = new Map();
  items.forEach((item, index) => {
      itemIndexMap.set(item._id, index);
  });

  // Step 2: Create the IndexMap for questions
  const indexMap = questions.map((que) => itemIndexMap.get(que._id));

  return indexMap;
}

  
const getResult = (selectedList) => {
  try {
    let cScore = 0;
    let selectedString = '';
    let retakeString = '';
    
    // Iterate over each question
    questions.forEach((question, index) => {
      const selectedValues = selectedList[index]?.map(Number)?.sort(); // Convert selected answers to numbers and sort
      const answerValues = question?.answers?.map(Number)?.sort(); // Convert correct answers to numbers and sort
      
      // Adjust for true/false questions
      if (question?.qtype === 0) {
        answerValues[0] = Math.max(answerValues[0] - 1, 0);
      }

      // Check if selected answers match correct answers
      const isCorrect = JSON.stringify(selectedValues) === JSON.stringify(answerValues);
      if (isCorrect) cScore++; // Increment score if answer is correct
      
      // Build retakeString
      retakeString += `${question?._id}|`;
      if (question?.qtype === 2) {
        // For multiple-choice questions, convert selected answers to alphabet
        const multiAns = question?.answers?.map(numberToAlphabet);
        retakeString += `${multiAns}|`;
      } else {
        // For other types, convert selected answers to alphabet
        retakeString += `${numberToAlphabet(selectedList[index])},`;
      }

      // Build selectedString
      selectedString += selectedList[index] + ':';
    });

    // Update state variables
    setScore(cScore);
    setIsConfetti(true);
    setShowScore(true);
    setRetakeProgress([{ s: cScore, r: retakeString }]);

    // Remove commas from selectedString
    selectedString = selectedString.replace(/,/g, '');

    // Calculate pass score
    const passScore = (cScore / questions?.length) * 100;

    // Check if pass score meets minimum requirements
    const minPass = passScore >= (valuePresentCheck(config?.validation?.min, globalSettings?.min) || 80);
   
    /**
     * Display the pre-test feature based on global settings and home page.
     * 
     * If conditions are met, the pre-test will be shown to the user.
     * If the user reaches 100% in the pre-test, the course will be marked as completed.
     * 
     */
    if(isPreTest){
      if(minPass)props?.pretestCourseCompletion(true);
      else props?.pretestCourseCompletion(false);
    }

    // Prepare payload for sending mail
    const mailTemplates = getStringToArray(config?.validation?.fail?.rule?.mail) || [];
    const payload = {
      tId: mailTemplates[configure?.rt|| 0] || 0,
      res: { s: cScore, r: retakeString },
      attempt: track.state?.r || {},
      config: config,
      type: 0 // type 0 sendQuizAttemptMail every fail || 1 quizAttemptsList -- max attempt fail
    };

    let isRad=valuePresentCheck(config?.config?.sel_ques?.value,max_select);
    if(isRad){
      let IndexMap=createIndexMap(questions,props?.items);
      selectedString+=cScore+'_'+1+'_'+IndexMap.join(':')?.trim();
    }else selectedString+=cScore;

    // Update progress
    updateProgress({ type: 'UPDATE', s: selectedString, b: 1 });

    // Handle failed attempts
    if (configure?.rt  >= (config?.validation?.attempt || 100) && !minPass) {
      if (config?.validation?.fail?.rule?.lock_crs.value) {
        setIsReset(2);
      }
      updateProgress({ type: 'UPDATE', s: selectedString, b: 2 });
      return;
    }

    // Send mail for failed attempts
    if (config?.validation?.fail?.rule?.send_respmail?.value && !minPass) {
      sendQuizReport({ ...payload, type: 0 });
    }

    // Send list IDs for failed attempts
    if (config?.validation?.fail?.rule?.send_atmptmail_touser?.value && !minPass) {
      sendQuizReport({ ...payload, type: 1 });
    }

    // End quiz or initiate retake if necessary
    if (!valuePresentCheck(config?.config?.ques_thrash?.value, globalSettings?.strict) || minPass) {
      updateProgress({ type: 'END', s: selectedString});
    } else if (getStringToArray(config?.validation?.fail?.rule?.rcoa)?.includes(configure?.rt || 0) && Number(track?.state?.status) !== 1) {
      updateProgress({ type: 'RETAKE', r: [...track?.state?.r || [], ...retakeProgress]});
      resetCourse();
      setIsReset(1);
    }
  } catch (error) {
    console.error('Error in getResult function:', error);
  }
}

// reseating course 
const handleResetCourse=()=>{
  if(isReset===2){
    lockCourse();
    return ;
  }
  retake();
  reDirect('/');
}

// helps to decode the track.state s String
const getSelectedList = (st) => {
  try{
    // Split the string by underscore
    const parts = st.split('_');
    let selectedString, score, randomize, randomizeOrder;
      // Determine format based on the number of parts
      if (parts?.length === 1) {
        selectedString = parts[0];
        selectedString=selectedString?.split(':')?.map((part, index, array) => {
          // If it's the last element, don't split it into individual characters that one score
          if (index === array.length - 1) {
            return Number(part||0);
          } else {
            return part?.split('')?.map(Number);
          }
        })
        score= Number(selectedString[selectedString?.length-1]);
        randomize=0;
        randomizeOrder=[]

      }else if (parts?.length === 3) {
        // [ selectedString:score _ randomize (0 or 1) _ randomize order (0 or Index- eg..0:3:1) ]
        [selectedString, randomize, randomizeOrder] = parts;
        selectedString=selectedString?.split(':')?.map((part, index, array) => {
          // If it's the last element, don't split it into individual character that one score
          if (index === array.length - 1) {
            return Number(part||0);
          } else {
            return part?.split('')?.map(Number);
          }
        })
        score= Number(selectedString[selectedString?.length-1]);
        randomizeOrder=randomizeOrder?.split(':')?.map((i)=>props?.items[i])||[]
      }
      selectedString?.pop();
    return {
      // Remove the last element from selectedArr and assign it to score
      selectedString: selectedString,
      score: score,
      randomize: Number(randomize),
      randomizeOrder: randomizeOrder||[]
    }
 }catch(e){
  console.error("quiz",e);
 }
}

/**
* Manage the component update 
* progress logic in this method
* 
* Update the view status when ever the user interacts
* Update the progess status ONLY ONCE, when status completes
* 
* *** s- String handles the selected list
* the last index of s String is Score
* 0:0:1
* r - quiz retake count 
* b - score board track- locked is 1 score board 2 locked else 0
*
*[ selectedString:score _ randomize (0 or 1) _ randomize order (0 or Index- eg..0:3:1) ]
* 
*/
const updateProgress = (currentItem) => {
  // eslint-disable-next-line default-case
  if(props?.isPreTest)return;
  switch (currentItem.type) {
    case 'END':
      if (track?.status === 0 ||!track?.status) {
        track.state = { ...track.state, s: currentItem?.s };
        track.status =1
        setInstruct({
          ...instruction,
          text: `You have completed this interactivity`,
          className: 'completed'
        })
        setStatus({ completed: true, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
        handler({ type: SAVE_PROGRESS_STATUS, id: track?.id, name: cename, track:track });
      }
      break;
    case 'RETAKE':
      // if (track.status === 1) {
        track.state = { ...track.state, r:currentItem?.r||track.state?.r||[],c:currentItem?.l||0,b:currentItem?.b||track.state?.b};
        track.status = 0
        setInstruct({
          ...instruction,
          text: `Select Correct Option`,
          className: 'not-completed'
        })
        setStatus({ completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE })
        handler({ type: SAVE_PROGRESS_STATUS, id: track?.id, name: cename, track:track});
      // }
      break;
      case 'UPDATE':
        track.state = { ...track.state, s:currentItem?.s||track.state?.s||"", b:currentItem?.b||track?.b||-1 };
        track.status = 0
        setInstruct({
          ...instruction,
          text: `Select Correct Option`,
          className: 'not-completed'
        })
        setStatus({ completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE })
        handler({ type: UPDATE_STATUS, id: track?.id, name: cename });
        break;
  }
}


useEffect(()=>{
  try{
    if(track && track?.state && !props?.isPreTest){
      const isTrack=track?.status === 1 || status?.completed || track?.state?.b===1 ||  track?.state?.b===2 ||  track?.state?.b===0;
      //  retake is present setting back
      setConfigure({...configure,rt:(track?.state?.r?.length)+1||1})
      if(track.state?.s && (isTrack)){
        setShowScore(true);
        let progress=getSelectedList(track?.state?.s);
        setSelectItem([...progress?.selectedString]);
        if(progress?.randomize===1){
          setQuestions([...progress?.randomizeOrder])
        }else setQuestions([...props?.items] || [])
        setScore(progress?.score||0);
      }
    }
  }catch(e){}
},[track?.state?.s, track?.state?.r,  track?.state?.b ]);

const viewQuiz = () =>{
  setShowScore(false);
  setIsView(true);
  // min step 0
  setActiveStep(Math.max(questions?.length-1, 0));
}
const handleAlert=()=>{setIsRetake(!isRetake)}
const retake = ()=>{
  setShowScore(false);
  setIsView(false);
  setActiveStep(0);
  setCompletedIDX(0);
  setSelectItem([]);
  setIsFeedBack(false)
  // && !config?.validation?.success?.rule?.lock?.value
  // Number(track?.status) !==1
  if(Number(track?.status) !==1){
    updateProgress({type:'RETAKE',r:[...track?.state?.r||[],...retakeProgress],b:-1});
  } 
  setIsRetake(false);
  setConfigure(prevState => ({ ...prevState, rt:(prevState?.rt||1) + 1 }));
}

const getScorePercentage=()=>{
  return Math.round(String((score / questions?.length) * 100))
}

const isPassCheck=()=>{
  return Math.round(String((score / questions?.length) * 100)) >= String(valuePresentCheck(config?.validation?.min,globalSettings?.min)||80) || false
}
useEffect(() => {
  if (!stopConfetti && showScore && isConfetti && isPassCheck()) {
    setStopConfetti(true)
    setTimeout(() => {
      setStopConfetti(false)
    }, 15000);
  }
}, [isConfetti,showScore,t]);

// Show the correct answer based on auto-check
const getValue=(index)=>{
if(configure?.autoCheck) return questions[activeStep].answers.some(e=>Number(e)===index);
return selectItem[activeStep]?.some(e=>Number(e)===index)
}

const singleAnswer=()=>{
  try{
  if(configure?.autoCheck ){
      if (questions[activeStep]?.qtype === 0) {
        let crtQuiz={...questions[activeStep]};;
        return [Math.max(crtQuiz?.answers[0] - 1, 0)];
      }
     return questions[activeStep]?.answers;
  }
  return selectItem[activeStep]
}catch(e){}
}

const handleDisable=()=>{
  try{
  const currentQuestion = questions[activeStep];
  if(completedIDX > activeStep && Number(currentQuestion?.hasfeedback)===1){
    return true;
  }
  return false
}catch(e){
  return false
}
}

  const scoreBoard = () => {
    const isPassed = isPassCheck();
    const scorePercentage = getScorePercentage();
    const requiredPassPercent = valuePresentCheck(config?.validation?.min, globalSettings?.min) || 80;
    const successMessage =(isPassed ? config?.validation?.success?.rule?.card_msg?.value : config?.validation?.fail?.rule?.card_msg?.value) || "";
    return <>
      <div className='score-board'>
        <div className='confetti'>
          {prevState => ({ ...prevState, rt: (prevState?.rt || 1) + 1 }) && <Confetti numberOfPieces={stopConfetti ? 400 : 0} width='1800px' height='250px' style={{ position: 'absolute' }} />}
        </div>
        <div className="img_congratualte">
          {isPassed && <img src={Congratulate} height="150px" width="150px" />}
          <div className="Text_congratulate">
            {isPassed && (
              <>
                <div className="font_congratulate">{t("CONGRATULATION")}</div>
                <div className="font_congratulate_2">{t("GREAT_JOB")}</div>
              </>
            )}
            <div className="font_congratulate_1">{t("QUIZZES_COM_MSG")}!</div>
            <div className="font_congratulate_3">
              {t("YOUR_SCORE")} <b>{scorePercentage}%</b> {t("WITH")} <b>{score}</b> {t("OUT_OF")}
              <b> {questions?.length}</b> {t("CORRECT_ANS")}
            </div>
            {!isPassed && (
              <div className="font_congratulate_4">
                {t("REQUIRED_PASS_PERCENT")} : {requiredPassPercent}%
              </div>
            )}
            <div dangerouslySetInnerHTML={{ __html: successMessage }} />
          </div>
        </div>
        <div className="vt-btn">
          {/* <Button onClick={()=>setIsAnalysis(true)} > View Response</Button> */}
          <AppButton theme="primary1_transparent" onClick={viewQuiz} > {t("VIEW")}</AppButton>
          {(!(isPassed && config?.validation?.success?.rule?.lock?.value) && (Number(configure?.rt || 0)) < Number(config?.validation?.attempt || (isPreTest ? 0 : 100))) && <AppButton theme="primary1_transparent" onClick={handleAlert} >{t("RETAKE")}</AppButton>}
        </div>
      </div>
    </>
  }

  const getImageUrl = (path)=>{
    return DataHelper.getResourcePath(0,path);
  }
  const hasImage = () => {
    try {
      const question = questions?.[activeStep || 0]; 
      return question?.images?.length ? question.images[0] : false;
    } catch (e) {
      console.log(e);
      return false;
    }
  };
  return (<>
    <div className='quizzes-continuer' >
     { !props?.isPreTest && <Instruction isInstruction={instruction?.enabled} completed={(track?.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text} />}
      <Paper className='paper-data'>
        {(showScore) ? (
          scoreBoard()
        ) : (
          questions?.length > 0 ? <div className='quiz-inner-continuer' id={questions[activeStep]?._id||-1}>
            <div className={` ${ hasImage() ? "quiz-box" : ""}`}>
            {hasImage() && <div className="img">
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img src={getImageUrl(questions[activeStep||0]?.images[0]||"")} />
            </div>} 

              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label" className='title'> {questions?.[activeStep]?.question}</FormLabel>
                {questions?.[activeStep] && questions[activeStep]?.qtype && questions[activeStep]?.qtype === 2 ?
                  <FormGroup  className='group-selection'>
                    {questions?.[activeStep]?.options?.map((ans, index) =>{
                      let isDisable=handleDisable();
                     return ( <FormControlLabel key={index}
                        className="radio_multi ck-check-box"
                        control={<Checkbox className="radio" disabled={isDisable} checked={getValue(index)|| false} value={index} onChange={(e) => onHandleSelect(e, 2)} name={ans} />}
                        label={ans} />)
                      })}
                  </FormGroup> :
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    className='group-selection'
                    value={singleAnswer()?? ""}
                    onChange={(e) => onHandleSelect(e, 1)}>
                   {questions?.[activeStep]?.options?.map((ans, i) => {
                      let isDisable=handleDisable();
                      return (
                        <FormControlLabel 
                          disabled={isDisable} 
                          key={i} 
                          className={`${questions[activeStep]?.qtype === 0 ? 'true_false' : ''} radio_multi ck-check-box`} 
                          value={i} 
                          control={<Radio className='radio' />} 
                          label={ans || 'Empty Label'} 
                        />
                      );
                    })}
                  </RadioGroup>}
              </FormControl>
              </div >
              {(((isFeedBack && selectItem[activeStep]) || isView) && questions?.[activeStep]?.hasfeedback == 1 || configure?.autoCheck)&& <div className='feed-back'>
                {getFeedBackResult(activeStep) ? <>
                   <div className='inner-box'><span><CheckCircleIcon className='correct'/></span><p className='feedback_text' dangerouslySetInnerHTML={{ __html: questions?.[activeStep]?.feedback[0] }}></p></div>
                   </>
                  : <>
                    <div className='inner-box'><span><Close className='wrong'/></span> <p className='feedback_text' dangerouslySetInnerHTML={{ __html: questions?.[activeStep]?.feedback[1] }}></p></div>
                  </>
                }
              </div>}
            
            <div className='bottom'>
              <div className="buttons">
                {(configure?.isQuizTry) ? 
                  <AppButton  theme="primary1" onClick={handleTryAgain}><ReplayIcon /> {t("TRY_AGAIN") }</AppButton > : <>
                  <AppButton  theme="primary1"  onClick={handleBack} disabled={activeStep === 0}> {t("BACK")} </AppButton >
                  <AppButton  theme="primary1"  onClick={handleNext} disabled={selectItem && (selectItem[activeStep] === undefined || selectItem[activeStep] === null)}> {(questions?.[activeStep]?.hasfeedback === 1 && !isFeedBack && !isView)? t("SUBMIT") :t('NEXT')} </AppButton>
                </>}
              </div>
              <div className="pagination_Numbers">
                <Stack spacing={2}> <Pagination count={questions.length} className="pagination" color="primary" size="medium" hidePrevButton hideNextButton page={activeStep + 1} /> </Stack>
              </div>
            </div>
          </div> : null
        )}
      </Paper>
      <div className='warning-popup'>
        <CustomSweetAlert
            warning
            showCancel
            show={isRetake}
            confirmBtnText={t("RETAKE")}
            cancelBtnText={t("CANCEL")}
            confirmBtnBsStyle="danger"
            title={<p className="sweet-title">{t("SCORE_ERASED_MSG")}</p>}
            onConfirm={ () =>retake()}
            onCancel={ () => handleAlert()} 
          />

          <CustomSweetAlert
            warning
            // showCancel
            className="confirm-popup"
            closeOnClickOutside={false}
            show={isReset===1 ||isReset===2}
            confirmBtnText={t("OK")}
            cancelBtnText={t("CANCEL")}
            confirmBtnBsStyle="danger"
            title={<p className="sweet-title">{`Sorry, you have failed. You have reached the maximum number of attempts. Your course will be ${isReset===2 ? "locked" :"reset"}.`}</p>}
            onConfirm={()=>handleResetCourse()}
          />
       </div>
    </div></>
  );
}

Quizzes.defaultProps = {
  instructions: {
    text: 'Select Correct Option',
    enabled: false
  },
  config:{},
  max_select:null,
  randomization:null,
  track: new ContentTracking(-1, 0, {})
}

Quizzes.propTypes = {
  /** Heading title text of the component */
  title: PropTypes.string,
  /** Description text is used for displaying explanation over the topic  */
  description: PropTypes.string,
  /** Question for the radioSelect component  */
  question: PropTypes.string,
  /** options array  */
  options: PropTypes.array,
  /** right or wrong information */
  explanation: PropTypes.object,
  /** answer id in number */
  answer: PropTypes.number,
  /** Tracking the component progress */
  track: PropTypes.instanceOf(ContentTracking),
  /** Func description for tracking*/
  handler: PropTypes.func
}


export default Quizzes;